<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-3 vocabulary-category">
          <div class="d-flex my-3" id="introduction">
            <h3 class="title pr-3">單字分類</h3>
            <div class="line"></div>
          </div>
          <div class="rwd-select">
            <select class="mx-2" v-model="currentPage" @change="currentSubPage=-1">
              <option v-for="(item, index) in categories" :key="index" :value="item.indexno+1">{{item.name}}</option>
            </select>
            <select v-model="currentSubPage" @change="goList(categories[currentPage-1].submenu[currentSubPage])">
              <option value="-1" selected>請選擇</option>
              <option v-for="(el, index) in categories[currentPage-1].submenu" :key="index" :value="index">{{el.name}}</option>
            </select>
          </div>
          <div v-if="type === 'email'">
            <div class="my-4 rwd-hide" v-for="(item, index) in categories" :key="index">
              <div>
                <div class="topic-btn pointer" :class="{'btn-active': currentPage === item.submenu[0].partid}"   @click="openMenu(item.submenu[0])">
                  <p>{{item.name}}</p>
                  <p>{{item.name_en}}</p>
                </div>
                <div class="submenu" :class="{'close': currentPage !== item.submenu[0].partid}">
                  <div class="content" :class="{'active': currentSubPage === subIndex}" v-for="(el, subIndex) in item.submenu" :key="subIndex"  @click="goList(el)">
                    <p class="m-0 pointer px-2">{{el.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="my-4 rwd-hide" v-for="(item, index) in categories" :key="index">
            <div>
              <div class="topic-btn pointer" :class="{'backgroundActive': currentPage === item.submenu[0].partid}"  @click="openMenu(item.submenu[0])" v-bind:style="{ 'background-image': 'url(' +  item.bg + ')' }">
                <p>{{item.name}}</p>
                <p>{{item.name_en}}</p>
              </div>
              <div class="submenu" :class="{'close': currentPage !== item.submenu[0].partid}">
                <div class="content" :class="{'active': currentSubPage === subIndex}" v-for="(el, subIndex) in item.submenu" :key="subIndex"  @click="goList(el)">
                  <p class="m-0 pointer px-2">{{el.name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-9" id="vocabulary"  style="height:1450px">
          <div class="d-flex my-3" id="introduction">
            <h3 class="title px-2">單字列表</h3>
            <div class="line"></div>
          </div>
          <div v-if="type === 'email'" class="row" style="overflow: auto; height:90%">
            <div v-for="(item, index) in vocabularies" :key="index" class="vocabulary-content col-12">
              <h5 class="vocabulary">
                <img @click="playAudio(item.id, 'vocabulary')" class="pointer" src="../../assets/image/pic/icon_voice_red.png" alt="">
                {{item.word}}
              </h5>
              <audio :id="`vocabulary${item.id}`">
                <source :src="item.word_url" type="audio/mp3">
              </audio>
              <p class="ml-4">
                {{item.s_en}}
              </p>
              <span class="badge badge-primary">解釋</span>
              <span class="d-flex align-items-start mt-2">
                <img class="pointer mr-2" @click="playAudio(item.id, 'sent')" src="../../assets/image/pic/icon_voice_bule.png" alt="">
                <p v-if="item.sent !== '<null>'" class="example" v-html="formatWord(item.sent_en)"></p>
              </span>
              <audio :id="`sent${item.id}`">
                <source :src="item.sent_url" type="audio/mp3">
              </audio>
            </div>
          </div>

          <div v-else class="row" style="overflow: auto; height:90%">
            <div v-for="(item, index) in vocabularies" :key="index" class="vocabulary-content col-12">
              <h5 class="vocabulary">
                <img @click="playAudio(item.id, 'vocabulary')" class="pointer" src="../../assets/image/pic/icon_voice_red.png" alt="">
                {{item.word}}
              </h5>
              <audio :id="`vocabulary${item.id}`">
                <source :src="item.word_url" type="audio/mp3">
              </audio>
              <h6 class="ml-4">
                <span v-for="(el) in item.phon.split(' ')" :key="el">
                  [<span class="phon">
                    {{el.replaceAll('{', '').replaceAll('}', '')}}
                  </span>]
                </span>
              </h6>
              <p class="ml-4">
                {{item.s_en}}
              </p>
              <span class="badge badge-primary ml-4">例句</span>
              <p v-if="item.sent !== '<null>'" class="example ">
                <img class="pointer" @click="playAudio(item.id, 'sent')" src="../../assets/image/pic/icon_voice_bule.png" alt="">
                {{item.sent}}
              </p>
              <audio :id="`sent${item.id}`">
                <source :src="item.sent_url" type="audio/mp3">
              </audio>
              <p class="ml-4">{{item.sent_en}}</p>
              <span v-if="item.exp_tw !== '<null>'" class="badge badge-dark ml-4">解釋</span>
              <p class="ml-4" v-if="item.exp_tw" v-html="formatWord(item.exp_tw)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../store/api'
export default {
  name: 'Ielts-vocabularies',
  components: {

  },
  data () {
    return {
      categories: null,
      vocabularies: null,
      currentPage: this.$route.params.id,
      currentSubPage: this.$route.params.subId
    }
  },
  computed: {
    type () {
      switch(this.$route.name) {
        case 'Ielts-List':
          return 'ielts'
        case 'Toeic-List':
          return 'toeic'
        case 'email-List':
          return 'email'
      }
    },
    routeName () {
      return this.$route.name
    }
  },
  mounted () {
    this. currentPage = this.$route.params.id
    this.getCategories()
    this.getVocabularies()
  },
  methods: {
    async getVocabularies () {
      const { data } = await api.get(`/contents/${this.type}/words?part=${this.currentPage}&index=${this.currentSubPage}`)
      this.vocabularies = data.data
    },
    async getCategories () {
      const { data } = await api.get(`/contents/${this.type}/categories`)
      this.categories = data.data
    },
    openMenu(el) {
      this.currentPage = el.partid
      this.currentSubPage = null
    },
    goList (el) {
      console.log(document.getElementById('vocabulary'))
      this.$router.push({ name: this.routeName , params: { id: el.partid, subId: el.indexno || 0 } })
    },
    playAudio (id, type) {
      this.isPlay = id
      this.playSentences(id, type)
    },
    playSentences (id, type) {
      let audio = document.getElementById(`${type}${id}`)
      this.pauseAll()
      audio.play()
    },
    // audioPasue (el, type) {
    //   this.isPlay = ''
    //   const audio = document.getElementById(`${type}${el.id}`)
    //   audio.pause()
    // },
    pauseAll () {
      let audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        i.currentTime = 0
        i !== self && i.pause()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/custom.scss";

  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .container {
    text-align: left;
  }
  .topic-btn {
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    background-position: center;
    object-fit: cover;
    background-image: url('../../assets/image/pic/btn_emailSentence_S.jpg');
  }
  .btn-active {
    background-image: url('../../assets/image/pic/btn_emailSentence_S_click.jpg');
  }
  .option-btn {
    text-align: center;
    width: 100%;
    border: black solid 1px;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    h5 {
      font-size: 1rem;
      padding: 0;
      margin: 0;
    }
    h6 {
      font-size: 0.8rem;
      padding: 0;
      margin: 0;
    }
  }
  .option-btn:hover {
    background-color: red;
    color:#fff;
  }
  .submenu {
    font-weight: normal;
    font-size: 1rem;
    width: 100%;
    padding: 2px;
    text-align: left;
    overflow: hidden;
    transition: .2s;
    background-color: #fff;
    .content:hover {
      background-color: rgb(255, 170, 170);
      color: red;
      font-weight: bolder;
    }
  }
  .active {
    background-color: rgb(255, 170, 170);
    color: red;
    font-weight: bolder;
  }
  .backgroundActive {
    background-color: rgba(253, 57, 57, 0.6);
    background-blend-mode: multiply;
  }
  .vocabulary-content {
    padding: 20px;
    text-align: left;
    border-bottom: solid 1px rgb(138, 138, 138);
    .vocabulary {
      color: red;
    }
    .example {
      color:#17176e;
    }
  }
  .close {
    opacity: 0;
    height: 0;
  }
  @media (max-width: 480px) {
    .topic-btn {
      height: 45px !important;
      overflow: hidden;
    }
    .rwd-hide {
      display: none;
    }
  }
  @media (min-width: 480px) {
    .rwd-select {
      display: none;
    }
  }
</style>
