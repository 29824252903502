export const cn = {
  Navbar: {
    about_TOEIC: '测验简介',
    about_Ielts: '雅思攻略',
    must_know_vocabulary: '必考单字',
    Biz_talk: '真人口说',
    newsletter: '电子学习报',
    essay_revision: '写作教室',
    email_sentence: '电子信箱 好用句',
    overview: '项目总览'
  },
  Overview: {
    classify: '学前测验',
    time: '学习总时数',
    course: '通过课程数',
    practice: '通过课后挑战',
    exam: '完成月考数',
    exam_score: '月考分数',
    exam_advance: '任一月考>学前测验',
    exam_advance_with_percent: '月考进步百分比',
    writing_count: '完成写作篇数'
  },
  Course_Overview: {
    cefr_level: 'CEFR 等级',
    A1: '（基础级雅思1）',
    A2: '（初级雅思3）',
    B1: '（中级雅思4）',
    B2: '（中高级雅思5.5）',
    C1: '（高级雅思7）',
    C2: '（精熟级雅思8.5）',
    course_category: '课程类别',
    life: '生活',
    biz: '商业',
    news: '新闻',
    toeic: '多益',
    learning_resources: '学习资源',
    unit_test: '单元测试',
    passed: '已通过',
    unpassed: '未通过'
  },
  Course_Overview: {
    cefr_level: 'CEFR 等级',
    A1: '（基础级雅思1）',
    A2: '（初级雅思3）',
    B1: '（中级雅思4）',
    B2: '（中高级雅思5.5）',
    C1: '（高级雅思7）',
    C2: '（精熟级雅思8.5）',
    course_category: '课程类别',
    life: '生活',
    biz: '商业',
    news: '新闻',
    toeic: '多益',
    learning_resources: '学习资源',
    unit_test: '单元测试',
    passed: '已通过',
    unpassed: '未通过'
  },
  Login: {
    register: '我要报名',
    trial: '申请试用',
    login: '登入',
    log_out: '登出',
    start_my_course: '登入学习',
    please_enter_your_email: '请输入电子信箱...',
    please_enter_password: '请输入密码...'
  },
  Home: {
    Daily_English: '每日一句',
    World_News: '全球新闻',
    Lets_play_in_English: '英文好好玩',
    FunEnglish: '跟着老师一起，玩游戏、学单字、培养英语语感力!',
    Learning_History: '近期学习课程',
    Latest_Courses: '最新课程',
    Recommend: '推荐课程',
    Learning_Guides: '学习指引',
    Go_to_course: '前往课程',
    Play: '播放音档',
    Keep: '收藏',
    watch_more: '看更多'
  },
  Course: {
    Description_video: '讲解',
    Interactive_learning: '互动学习',
    Unit_test: '单元测验'
  },
  Personal: {
    PersonalArea: '个人专区',
    Info: {
      Total_study_time: '学习时数',
      Number_of_courses_passed: '通过课数',
      Number_of_monthly_exams: '月考次数',
      First_time_login: '首次登入'
    },
    Updated_password: {
      Change_password: '修改密码',
      Name: '姓名',
      Email: '账号',
      Current_password: '输入旧密码',
      new_password: '输入新密码',
      Confirm_password: '密码再确认',
      Save: '储存',
      Company: '公司',
      Class: '数',
      Period: '课程期间',
      Email_remind: '请输入您的账号',
      Change_Account_Remind: '账号等同于主要信箱不可变更',
      Current_password_remind: '请输入你的旧密码',
      new_password_remind: '请输入英文或数字的文字组合',
      Reenter_password_remind: '请再输入一次您的新密码'
    },

    Target: {
      Target: '学习目标',
      Goal: '目标',
      Result: '结果',
      Completed: '已完成',
      Unit: '课',
      Points: '分',
      Time: '次'
    },
    Record: {
      Learning_records: '学习纪录',
      View_all: '总览',
      Date_settings: '日期区间',
      Learning_hours: '学习时数',
      Number_of_completed_courses: '完成课数',
      Number_of_unit_tests_passed: '通过课后挑战数',
      Details: '详细内容',
      Course_categories: '课程分类',
      History: '最近学习时间',
      Unit_test_score: '单元测验分数',
      Go_to_course: '前往课程',
      Writing: '写作纪录'
    },
    Collection: {
      Keep: '收藏',
      Vocabulary: '单字',
      Lesson: '课程'
    },
    Exam: {
      ExamArea: '测验专区',
      Placement_test: '学前测验',
      Result: '分级结果',
      Exam_date: '测验日期',
      Answering_time: '作答时间',
      Listening_score: '听力成绩',
      Reading_score: '阅读成绩',
      Total_score: '总分',
      Monthly_mock_test: '每月模考',
      More_practice: '更多练习',
      Updated_exam_date: '最近测验日期',
      Number_of_exercises: '练习次数',
      Start: '开始',
      Details: '详解'
    }
  },
  Footer: {
    About: '关于我们',
    About_content: 'MI Learning品牌由Magazines International (Asia) Limited (马己仙国际(亚洲)有限公司)创立。我们搜罗全球具教学权威的互动语文课程，以创新的多媒体在线课程、专业外籍老师，提供全方位听说读写的学习环境，汇聚语文教学法的精髓(学习、练习、应用)，多年来致力为国际知名的社企、私人学员提供外语培训，实现更有效的自我增值。'
  }
}
