<template>
  <div class="fixed-top" style="z-index:500;">
    <nav class="navbar navbar-light bg-light">
      <img class="pointer" style="width:120px" @click="$router.push({ name: 'Home'})" src="../assets/image/pic/logo_miLearning.png" alt="">
      <div>
        <span class="mx-sm-2">
          <span @click="goPage('search')" class="pointer">{{ $t('Navbar.overview') }}</span>
        </span>
        |
        <span class="mx-sm-2">
          <span @click="goPage('personal')" class="pointer">{{ $t('Personal.PersonalArea') }}</span>
        </span>
        |
        <span class="mx-2">
          <span v-if="!authorized" class="pointer">{{ $t('Login.login') }}</span>
          <span v-else @click="logout" class="pointer">{{ $t('Login.log_out') }}</span>
        </span>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light bg-light main py-0">
      <!-- <a class="navbar-brand pointer" style="font-size:1rem;" @click="$router.push({ name: 'Home'})">首頁</a> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" v-if="authorized && channels.length>0">
          <li class="nav-item dropdown" v-for="(item, index) in channels" :key="item.id">
            <a class="nav-link" role="button" data-toggle="dropdown" @mouseenter="selectedMain=null,main =index">
              <!-- {{item.name}}{{item.name_en}} -->
              {{locale!=="en"?item.name:item.name_en}}
            </a>
            <div :class="'main-menu'+index" class="dropdown-menu dropright main-menu  m-0">
              <div class="main-menu-item" v-if="item.slug === 'toeic'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goPage('raiders')">
                    {{ $t('Navbar.about_TOEIC') }}
                  </div>
                </a>
              </div>
              <div class="main-menu-item" v-if="item.slug === 'toeic'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goPage('Toeic-vocabularies')">
                    {{ $t('Navbar.must_know_vocabulary') }}
                  </div>
                </a>
              </div>
              <div class="main-menu-item" v-if="item.slug === 'ielts'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goPage('raiders-ielts')">
                    {{ $t('Navbar.about_Ielts') }}
                  </div>
                </a>
              </div>
              <div class="main-menu-item" v-if="item.slug === 'ielts'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goPage('Ielts-vocabularies')">
                    {{ $t('Navbar.must_know_vocabulary') }}
                  </div>
                </a>
              </div>
              <div class="main-menu-item" v-if="item.slug === 'learning_resources'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goPage('newsletter')">
                    {{  $t('Navbar.newsletter')}}
                  </div>
                </a>
              </div>
              <div class="main-menu-item" v-for="(el, index) in item.child"  @mouseover="mouseHover(el,index)" :key="index">
                <a @click="goPage(el.id)"  v-if="el.items.length>0" class="dropdown-item pointer d-flex justify-content-between" :class="{'active': el.id === selectedMain}">
                  <div class="menu-item" >
                    {{locale!=="en"?el.name:el.name_en}}
                  </div>
                  <div v-if="el.items.length>0" class="pl-5 arrow">
                    <font-awesome-icon class="pointer chevron" size="lg" :icon="['fa', 'chevron-right']" />
                  </div>
                </a>
                <div>
                  <div class="dropdown-menu sub-menu m-0" v-if="el.items.length>0" :style="{'height':adjustment}">
                    <div class="nav-item" v-for="(item, index) in el.items" :key="index">
                      <a class="dropdown-item pointer" @click.self="goSubPage(item.id)" >
                        {{locale!=="en"?item.name:item.name_en}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main-menu-item" v-if="item.slug === 'writing_courses'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goEmail">
                    {{ $t('Navbar.email_sentence')}}
                  </div>
                </a>
              </div>
              <div class="main-menu-item" v-if="item.slug === 'writing_courses'">
                <a class="dropdown-item topic-item pointer d-flex justify-content-between"  @mouseenter="selectedMain=null">
                  <div class="menu-item" @click="goWriting">
                    {{ $t('Navbar.essay_revision')}}
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <router-link :to="{ name: 'ai_assistantIntro' }" class="nav-link" @mouseenter="selectedMain = null, main = 7">
              AI小幫手
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import api from '../store/api'
export default {
  name: 'Navbar',
  data () {
    return {
      level: null,
      locale: window.localStorage.getItem('locale') || 'tw',
      channels: [],
      submenu: [],
      search: '',
      selectedMain: null,
      adjustment: '',
      main: ''
    }
  },
  computed: {
    ...mapGetters([
      'authorized'
    ])
  },
  watch: {
  },
  mounted () {
    this.getChannel()
    this.getPresonalData()
  },
  methods: {
    ...mapMutations({
      dologout: 'logout'
    }),
    async getPresonalData () {
      const { data } = await api.get('/me')
      this.level = data.level
    },
    goPage (id) {
      if (id === 'Toeic-vocabularies') {
        this.autoNavbar()
        this.$router.push({ name: 'Toeic-Vocabularies'})
      }else if (id === 'Ielts-vocabularies') {
        this.autoNavbar()
        this.$router.push({ name: 'Ielts-Vocabularies'})
      } else if (id === 'raiders') {
        this.autoNavbar()
        this.$router.push({ name: 'Raiders'})
      } else if (id === 'raiders-ielts') {
        this.autoNavbar()
        this.$router.push({ name: 'RaidersIelts'})
      } else if (id === 'newsletter') {
        this.$router.push({ name: 'NewsLetter'})
      } else if (id === 'personal') {
        this.$router.push({ name: 'Target'})
        // this.$router.push({ name: 'Record'})
      } else if (id === 'search') {
        this.$router.push({ name: 'Search', query: { cefr: this.level!=='NA'?this.level: '' }})
      } else {
        this.autoNavbar()
        this.$router.push({ name: 'Channels', params: { channel: id } })
        this.submenu = []
      }
    },
    autoNavbar () {
      if (document.body.clientWidth < 900) {
        const navBtn = document.querySelector('.navbar-toggler')
        navBtn.click()
      }
    },
    navbarHover () {
      document.addEventListener("DOMContentLoaded", function(){
      if (window.innerWidth > 992) {
          document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem) {
            everyitem.addEventListener('mouseover', function(e){
              let el_link = this.querySelector('a[data-bs-toggle]')
              if(el_link != null){
                let nextEl = el_link.nextElementSibling
                el_link.classList.add('show')
                nextEl.classList.add('show')
              }
            })
            everyitem.addEventListener('mouseleave', function(e){
              let el_link = this.querySelector('a[data-bs-toggle]')
              if (el_link != null) {
                let nextEl = el_link.nextElementSibling
                el_link.classList.remove('show')
                nextEl.classList.remove('show')
              }
            })
          })
        }
      })
    },
    goSubPage (id) {
      this.$router.push({ name: 'Course', params: { channel: id } })
    },
    goEmail () {
      this.autoNavbar()
      this.$router.push({ name: 'email-Vocabularies'})
    },
    goWriting () {
      this.autoNavbar()
      this.$router.push({ name: 'WritingList'})
    },
    async getChannel () {
      const { data } = await api.get('/contents/channels')
      this.channels = data.data
    },
    async getSubChannel (id) {
      const { data } = await api.get(`/contents/channels/${id}`, { headers: {'showLoading': false }} )
      this.submenu = data.data.items
    },
    logout () {
      this.$router.push({ name: 'Login' })
      this.dologout()
    },
    doSearch () {
      this.$router.replace({ name: 'Search', query: {q: this.search} })
    },
    mouseHover (el,index) {
      this.selectedMain = el.id
      if (index+1 > el.items.length) {
        this.adjustment = document.querySelector(`.main-menu${this.main}`).offsetHeight + 'px'
      } else {
        this.adjustment = ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
  .main {
    min-height: 45px;
    background-color: $main-color !important;
  }
  .navbar {
    a{
      font-size: 1.2rem;
      color: $white-color !important;
    }
    z-index: 0;
    }
  .dropdown-menu {
    background-color: $light-gray;
    // text-align: center;
    text-align: left;
    .main-title {
      line-height: 3rem;
      font-weight: bolder;
      border-bottom: black 1px solid;
    }
    a {
      color: #000 !important;
      .arrow {
        display: none;
      }
    }
    // .dropdown-item:hover,:active {
    //   background-color: $sub-color !important;
    //   color: #ffff !important;
    // }
    .active {
      color: #ffff !important;
      background-color: $sub-color !important;
    }
  }
  .search {
    width: 100%;
    position: relative;
    .fa-search {
      margin-left: 5px;
      background-color: #fff;
      cursor: pointer;
      color: rgb(117, 117, 117);
    }
  }

  .sub-menu {
    // min-height: 50vh;
    max-height: 80vh;
    overflow: auto;
    background-color: $light-gray!important;
    .dropdown-item:hover,:active {
      background-color: $gray-color !important;
      color: #ffff !important;
    }
  }
  .topic-item:hover {
    color: #ffff !important;
    background-color: $sub-color !important;
  }
  @media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu{ display: none; }
    .navbar .nav-item:hover .main-menu{ display: block; }
    .navbar .main-menu .main-menu-item:hover .sub-menu{ display: block; }
    .arrow {
      display: block !important;
    }
  }
  @media all and (max-width: 992px) {
    .menu-item {
      margin: auto;
    }
  }
</style>
