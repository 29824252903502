export const en = {
  Navbar: {
    about_TOEIC: 'About TOEIC',
    about_Ielts: 'About Ielts',
    must_know_vocabulary: 'Must-know Vocabulary',
    Biz_talk: 'BizTalk',
    essay_revision: 'Essay Revision',
    email_sentence: 'Email Sentence',
    newsletter: 'Newsletter',
    overview: 'Overview',
    essay_revision: 'Essay Revision',
    email_sentence: 'E-mail Sentence',
    challenge: 'Word Challenge'
  },
  Course_Overview: {
    cefr_level: 'CEFR',
    A1: '（TOEIC<225）',
    A2: '（TOEIC 225-550）',
    B1: '（TOEIC 550-785）',
    B2: '（TOEIC 785-945）',
    C1: '（TOEIC>945）',
    C2: '（TOEIC>945）',
    course_category: 'Category',
    life: 'Life',
    biz: 'Biz',
    news: 'News',
    toeic: 'TOEIC',
    learning_resources: 'Learning Resources',
    unit_test: 'Unit Test',
    passed: 'Passed',
    unpassed: 'Unpassed'
  },
  Login: {
    register: 'Register',
    trial: 'Sigh up for free trial',
    login: 'Log In',
    log_out: 'Log Out',
    start_my_course: 'Start my course',
    // please_enter_your_email: 'Please Enter Your Email',
    please_enter_password: 'Please Enter Your Password',
    forgot_Password: 'Forgot Password',
    please_enter_your_email: 'Please Enter Your Email',
    send_verification_email: 'Send Verification Email'
  },
  Overview: {
    classify: 'Classify',
    time: 'Time',
    course: 'Course',
    practice: 'Practice',
    exam: 'Exam',
    exam_score: 'Exam score',
    exam_advance: 'Advance',
    exam_advance_with_percent: 'Advance with percent',
    writing_count: 'Writing count'
  },
  Home: {
    Daily_English: 'Daily English',
    World_News: 'World News',
    Lets_play_in_English: "Let's Play in English",
    FunEnglish: 'Play games, learn vocabulary, and improve English skills with your teacher!',
    Learning_History: 'Learning History',
    Latest_Courses: 'Latest Courses',
    Recommend: 'Course Recommendations',
    Learning_Guides: 'Learning Guides',
    Go_to_course: 'Go to Courses',
    Play: 'Play',
    Keep: 'Keep',
    watch_more: 'Watch more'
  },
  Course: {
    Description_video: 'Learning Videos',
    Interactive_learning: 'Interactive Learning',
    Unit_test: 'Unit Test',
    Practice: 'Review Test',
    Record_study_time: 'Record study time'
  },
  Personal: {
    PersonalArea: 'My Account',
    Info: {
      Total_study_time: 'Total Study Time',
      Number_of_courses_passed: 'Passed Courses',
      Number_of_monthly_exams: 'Monthly Exams Completed',
      First_time_login: 'First Time Login'
    },
    Updated_password: {
      Change_password: 'Change Password',
      Name: 'Name',
      Email: 'Email',
      Current_password: 'Current Password',
      New_password: 'New Password',
      Confirm_password: 'Confirm New Password',
      Save: 'Save',
      Company: 'Company',
      Class: 'Class',
      Period: 'Period',
      Email_remind: 'Please enter your email.',
      Change_account_remind: 'Not available to change your account.',
      Current_password_remind: 'Please enter your current password.',
      New_password_remind: 'Password must contain characters A-Z, a-z or digit 0-9.',
      Reenter_password_remind: 'Please reenter your new password.'
    },
    Target: {
      Target: 'Learning Targets',
      Goal: 'Target',
      Completed: 'Completed',
      Result: 'Result',
      Point: 'point(s)',
      Unit: 'unit(s)',
      Time: 'time(s)'
    },
    Record: {
      Learning_records: 'Learning Records',
      View_all: 'View All',
      Date_settings: 'Period',
      Study_Time: 'Total Study Time',
      Number_of_completed_courses: 'Unit Test Passed',
      Number_of_review_test_passed: 'Review Test Passed',
      Details: 'Details',
      Course_categories: 'Categories',
      History: 'Recent Learning History',
      Unit_test_score: 'Unit Test Score',
      Go_to_course: 'Go to Course',
      Writing: 'Writing'
    },
    Collection: {
      Keep: 'Keep',
      Vocabulary: 'Vocabulary',
      Phrase: 'Phrase',
      Dictionary: 'Dictionary',
      Lesson: 'Lessons',
      Enter_course: 'Go to Course',
      Source_course: 'Go to Course'
    },
    Exam: {
      ExamArea: ' Exam Zone',
      Placement_test: 'Placement Test',
      Result: 'Level (CEFR)',
      Exam_date: 'Test Date',
      Month: 'Month',
      Test_time: 'Test Time',
      Listening_score: 'Listening Score',
      Reading_score: 'Reading Score',
      State: 'State',
      Total_score: 'Total Score',
      Monthly_mock_test: 'Mock Test',
      More_practice: 'More Practice',
      Updated_exam_date: 'Updated exam date',
      Number_of_exercises: 'Practice Time',
      Start: 'Start',
      Details: 'Details'
    }
  },
  Footer: {
    About: 'About',
    About_content: 'MI brand is limited by Magazines Intemational (Asia) International (Asia) Learning Co., Ltd. Our Rolls-Royce global professional teaching interactive courses, with innovative online multimedia courses, provide a full range of learning environments depicted by foreign teachers, and integrate the essence of Chinese teaching methods (learning, practice, application, helping international and more well-known enterprises Institutions), effectively provide foreign language training for themselves, to achieve value-added.'
  }
}
