<template>
  <div>
    <div class="container-fluid">
      <div class="row banner align-items-center">
        <div class="col-md-6 col-12">
          <div class="personal_info">
            <div class="enterprise">
              <h2 v-if="personalData.enterprise_name">
                {{personalData.enterprise_name}}
              </h2>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 group_info">
          <div class="d-sm-flex align-items-center">
            <div class="info-box">
              <div class="title">
                班別
              </div>
              <select v-model="selected">
                <option v-for="(item, index) in groupList" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div>
              <div class="info-box ">
                <div class="title">
                  <!-- <img style="height:30px" src="../assets/image/pic/icon_class_time.png" alt=""> -->
                  班級人數
                </div>
                <div class="content">
                  {{groupInfo.student_count?groupInfo.student_count:0}}
                </div>
              </div>
            </div>
            <div>
              <div class="info-box">
                <div class="title">
                  <!-- <img style="height:30px" src="../assets/image/pic/icon_class_time.png" alt=""> -->
                  課程期間
                </div>
                <div class="content">
                    {{moment(groupInfo.begin_at).format('YYYY/MM/DD')}} - {{ moment(groupInfo.end_at).format('YYYY/MM/DD')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex menu">
            <!-- <div class="title d-sm-flex pointer" :class="{'active' : $route.name === 'Target'}">
              <div class="my-auto">
                學員管理
              </div>
            </div> -->
            <div @click="goPage('Overview')" class="title  d-sm-flex pointer" :class="{'active' : $route.name === 'Overview'}">
              <div class="my-auto">
                總覽報表
              </div>
            </div>
            <div @click="goPage('PersonalDetail')" class="title d-sm-flex pointer" :class="{'active' : $route.name === 'PersonalDetail'}">
              <div class="my-auto">
                個人學習資訊
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <router-view :groupId="selected"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../../store/api'
export default {
  name: 'Director',
  data () {
    return {
      selected: this.$route.params.groupId,
      personalData: {},
      enterpriseId: 0,
      groupList: [],
      groupInfo: {}
    }
  },
  computed: {
  },
  watch : {
    selected (val) {
      this.getGroupInfo(val)
      this.$router.push({ params: { groupId: val}})
    }
  },
  mounted() {
    this.scrollTop()
    this.getPersonalData()
  },
  methods: {
    goPage (el) {
      switch (el) {
        case 'Overview':
          this.$router.push({ name: el, params: {type: 'summary', groupId: this.selected}})
          break
        case 'PersonalDetail':
          this.$router.push({ name: el, params: {groupId: this.selected}})
          break
      }
    },
    async getTarget (id) {
      const { data } = await api.get(`/records/students/${id}/learning_progress?pageSize=100000`)
      this.target = data.data
      this.target.segment = this.segment
      this.target.standard = this.personalData.enterprise_standard
    },
    async getPersonalData () {
      const { data } = await api.get('/me')
      this.personalData = data
      this.enterpriseId = data.enterprise_id
      this.getGroupList(data.enterprise_id)
      // this.getTarget(data.student_id)
    },
    async getGroupList (id) {
      const { data } = await api.get(`/enterprises/${id}/groups?pageSize=100000`)
      this.groupList = data.data
      this.getGroupInfo(this.selected)
    },
    async getGroupInfo (id) {
      const { data } = await api.get(`/groups/${id}`)
      this.groupInfo = data.data
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    min-height: 250px;
    height: 30vh;
    background-image: url('../../assets/image/pic/bg_member_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .cefr {
      padding: 2px;
      font-size: 1rem;
      line-height: 1rem;
      background-color: rgb(255, 0, 0);
      border: 2px solid #fff;
      border-radius: 20%;
    }
    h2 {
      margin: 0;
    }
  }
  .main {
    background-color: #eee;
  }
  .group {
    // text-align: center;
    width: 50%;
    margin: auto;
    font-size: 1.5rem;
  }
  .circle_picture {
    width: 100px;
    height: 100px;
    border-radius:50%;
  }
  .enterprise {
    text-align: left;
    color: #fff;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h2 {
      font-size: 3.5rem;
    }
  }
  .active {
    color: red;
  }
  .info-box {
    min-width: 150px;
    margin: 10px;
    .title {
      padding: 3px;
      background-color: #fff;
      font-weight: bold;
      color: #51A8DD;
    }
    select {
      width: 100%;
      padding: 10px;
      border: none;
      border-top: 1px solid #ccc;
    }
    .content {
      padding: 10px;
      color: #fff;
      border: 1px #fff solid;
    }
  }
  .menu {
    .title {
      margin: 20px;
      font-weight: bolder;
    }
  }
  @media(max-width: 1080px){
    .banner {
      height: 50vh;
    }
    // .banner-box {
    //   display: none;
    // }
  }
</style>
