<template>
  <div>
    <div class="banner">
      <div class="banner-box d-flex" >
        <div class="title-box">
          <div class="text-box">
          </div>
        </div>
        <div class="content-box" style="width:50%; height:60vh; padding:10vh;">
          <div class="p-3" style="height:100%;background:rgba(255, 255, 255, 0.9);text-align:left">
            <p style="height:70%">
              雅思專區提供雅思測驗IELTS (International English Language Testing System)應考準備相關豐富學習資源。除了測驗簡介，幫助您更瞭解測驗題型與準備方向，更有雅思必考單字、線上模擬測驗試題，供學員多元練習。讓您透過電腦考的方式，更加熟悉測驗中的出題情境與必備詞彙，掌握各題型應試策略。
            </p>
            <!-- <div style="text-align:center">
              <button type="button" @click="goAnchor('practice')" class="btn btn-danger btn-lg btn-sm" style="font-size:1rem">立刻進入多益模考</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="d-md-flex justify-content-between my-4 anctors">
        <div class="my-2">
          <button type="button" @click="goAnchor('introduction', -100)" class="btn btn-danger m-auto" style="width:100%">測驗簡介</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('content', -100)" class="btn btn-danger m-auto" style="width:100%">題型說明</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('exam-type', -100)" class="btn btn-danger m-auto" style="width:100%">類別及費用</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('guide', -100)" class="btn btn-danger m-auto" style="width:100%">模式說明</button>
        </div>
        <div class="my-2 rwd-hide">
          <button type="button" @click="goAnchor('frequency', -100)" class="btn btn-danger m-auto" style="width:100%">舉辦頻率</button>
        </div>
        <div class="my-2 rwd-hide">
          <button type="button" @click="goAnchor('total-score', -100)" class="btn btn-danger m-auto" style="width:100%">總分與各級分</button>
        </div>
        <div class="my-2 rwd-hide">
          <button type="button" @click="goAnchor('others', -100)" class="btn btn-danger m-auto" style="width:100%">相關說明</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="introduction">
            <h3 class="title pr-3"> 測驗簡介</h3>
            <div class="line"></div>
          </div>
          <div>
            IELTS®雅思檢定(The International English Language Testing System)是於1989年由劍橋大學英語考試院設計用來評估欲前往英語系國家求學、移民或工作者在聽、說、讀、寫等四項語言技巧的全方位英語溝通能力。是由
            劍橋英語(Cambridge Assessment English)、英國文化學會(the British Council)、
            IDP 澳洲國際文教中心 (IDP: IELTS Australia) 共同舉辦。
            目前可於全球約140個國家施測，因全球承認成績的學校與機構數量眾多，雅思測驗官方因而特別設計了線上查詢平臺，使用者可以透過平臺查詢到適用雅思測驗成績的學校機構以及對應的成績合格門檻。<a target="_blank" href="http://takeielts.britishcouncil.org/choose-ielts/who-accepts-ielts">英國文化協會</a>
            IELTS®成績不僅具備信度與效度，也具有國際流通度，足以體現考生在實際溝通情境中的英語文能力。
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="content">
            <h3 class="title pr-3">考試題型說明</h3>
            <div class="line"></div>
          </div>
          <div class="my-3">
            <p>主要為IELTS Academic 與IELTS General Training 兩種。前者針對「學術組」，提供給計畫至海外求學深造之考生；後者針對「一般訓練組」，提供給計畫移民至海外（不含英國）、申請非學位類課程之考生。</p>
            <p>此外，近幾年新增IELTS for UKVI（英國簽證雅思考試），同樣細分為學術組(Academic)與一般訓練組(General Training) 兩種。前者提供給計畫申請海外語言課程、大學學位以下課程之考生；後者提供給計畫移民至海外（含英國）、申請非學位類課程之考生。UKVI之考試方式與前述相同。</p>
            <p>最後一類名為IELTS Life Skills（雅思生活技能考試），針對欲申請家庭及配偶簽證為A1組，或是工作、居留簽證為B1組等。</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="exam-type">
            <h3 class="title pr-3">IELTS 測試類別及費用</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <p>各個 IELTS 考試類別有相應的費用，詳細考試費用可參考以下列表：</p>
            <img class="my-2" style="width:100%" src="../../assets/image/table/frame_IELTS_money.svg" alt="">
            <a href="https://www.britishcouncil.hk/exam/ielts/dates-fees-locations " target="_blank">考試費以官方公佈為</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="guide">
            <h3 class="title pr-3">考試模式說明</h3>
            <div class="line"></div>
          </div>
          <div class="my-3">
            <p>考生可報考「紙本」或「電腦」考場。兩者題目難易度、考試形式、範圍、評分標準無差別，不同點有二：</p>
            <ul>
              <li>紙本考之一切作答皆以鉛筆書寫在答案紙上；電腦考之一切作答皆在電腦上，適合較習慣打字之考生。</li>
              <li>紙本考之考試順序一律為：聽、讀、寫；電腦考之聽讀寫順序可能改變。</li>
            </ul>
            <p>在同一場紙本考試中，學術組與一般訓練組所考之聽力測驗內容相同，費時約40分鐘；接著進行60 分鐘之閱讀測驗（兩組之題目不同）；最後是60 分鐘之寫作測驗（兩組之題目不同）。不論是紙本考或電腦考，口說部分由考場另外安排時間讓考生與真人考官進行一對一的口試測試。</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="frequency">
            <h3 class="title pr-3">香港IELTS各類考試舉辦頻率</h3>
            <div class="line"></div>
          </div>
          <div class="my-3">
            <h5>IELTS 紙筆模式考試：</h5>
            <ul>
              <li>IELTS 紙筆模式考試所有筆試  (聆聽、閱讀及寫作部分) 將會在星期六上午 (上午 9 時至下午 12 時 30 分) 舉行</li>
              <li>會話測試則將會在進行筆試後當天的同日下午 (下午13時 15 分至19時)，或翌日 (星期日上午 9 時至下午 19 時) 進行</li>
              <li>會話測試亦有機會安排於平日舉行</li>
              <li>每個會話測試的時段為 20 分鐘</li>
              <li>會話測試的實際考試時間將會列明於測試准考證內 (如有任何更改，將會另行通知) </li>
              <li>請參閱網上報名系統內有關個別考期之會話測試日期</li>
            </ul>
            <h5>IELTS電腦模式考試：</h5>
            <ul>
              <li>IELTS 電腦模式考試一般每週 5 次。</li>
              <li>考試所有考卷將會在同日進行。所有筆試 (聆聽、閱讀及寫作部分)將會在上午進行(上午 9 時半到中午 12 時 30 分)</li>
              <li>會話考試一般會在下午進行 (下午 1 時到下午 17 時 30 分)。</li>
              <li>會話考試的實際考試時間將會列明於測試准考證內 (如有任何更改，將會另行通知) 。</li>
              <li>注: IELTS 電腦模式考試開考時間為 9 時半，考生需于開考前 30 - 45 分鐘到達考場應考</li>
            </ul>
            <h5>有關 IELTS 英國簽證及移民考試 (IELTS UKVI) 注意事項：</h5>
            <ul>
              <li>IELTS 英國簽證及移民考試 (IELTS UKVI) 所有筆試 (聆聽、閱讀及寫作部分) 將會在星期六上午 (上午 9 時至下午 12 時 30 分) 舉行。</li>
              <li>會話測試則將會在進行筆試後當天的同日下午 (下午 1 時 15 分至 7 時)，或翌日 (星期日上午 9 時至下午 7 時) 進行。會話測試亦有機會安排於平日舉行。</li>
              <li>每個會話測試的時段為 20 分鐘。</li>
              <li>會話測試的實際考試時間將會列明於測試准考證內（如有任何更改，將會另行通知）。</li>
              <li>請參閱網上報名系統內有關 IELTS 英國簽證及移民測試個別考期之會話測試日期。</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3">
            <h3 class="title pr-3">香港IELTS 考試地點 </h3>
            <div class="line"></div>
          </div>
          <div class="my-3">
            <p>英國文化協會及IDP 教育均提供 IELTS測試試場，一般於酒店、學校及會議中心舉行。場地多位於如港島（包括：金鐘、中環、灣仔、銅鑼灣等）和九龍（包括：旺角、尖沙咀、觀塘、荃灣等）以及沙田。澳門地區分別於本島及氹仔不同地點設有試場。</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="total-score">
            <h3 class="title pr-3">雅思考試的總分與各級分說明</h3>
            <div class="line"></div>
          </div>
          <div class="my-3">
            <p>雅思滿分為9 分，聽、說、讀、寫各部分分數從1 分到9 分，各分數以0.5   之級距呈現。以下列出各級分所代表之語言水準及說明。</p>
            <img class="my-2" style="width:100%" src="../../assets/image/table/frame_IELTS_grade.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="others">
            <h3 class="title pr-3">分數計算、考試時間間隔、成績相關說明及有效期</h3>
            <div class="line"></div>
          </div>
          <div class="my-3">
            <p>將聽、說、讀、寫各部分之分數相加除以4 即為所得之平均。但總平均呈現只會以0.5之級距秀出，舉例如下：</p>
            <ul>
              <li>考生Ａ： 聽、說、讀、寫分別為7、7、6、6，則算出平均為6.5，顯示在成績單為6.5。</li>
              <li>考生Ｂ： 聽、說、讀、寫分別為7、7、7、6，則算出平均為6.75，顯示在成績單為7.0。</li>
              <li>考生Ｃ： 聽、說、讀、寫分別為7、6、6、6，則算出平均為6.25，顯示在成績單為6.5。</li>
              <li>考生Ｄ： 聽、說、讀、寫分別為6.5、6、6、6，則算出平均為6.125，顯示在成績單為6.0。</li>
            </ul>
            <p>雅思考試並無限制兩場考試間之「時間間隔」，也就是同一人可連續兩周考雅思是沒問題的。因此，有需要在短時間內達到目標分數之考生，往往會一次連報好幾場。雅思成績一般在筆試日期後13 天產出，成績可保留二年有效。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../store/api'
export default {
  name: 'Vocabulary-raiders',
  components: {

  },
  data () {
    return {
      exams: null
    }
  },
  computed: {
  },
  mounted () {
    this.getExamData()
    this.scrollTop()
  },
  methods: {
    async getExamData () {
      const { data } = await api.get('/contents/exams?page=1&pageSize=10&type=simulation')
      this.exams = data.data
    },
    goPage (id, viewId) {
      this.$router.push({ name: 'Examination', params: { examId: id ,viewId: viewId } })
    },
    goAnchor(id, yOffset = 0) {
      const el = document.getElementById(id)
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/custom.scss";
  .banner {
    height: 60vh;
    background-image: url('../../assets/image/pic/banner_bg_IELTS_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .title-box {
      width: 50%;
      .text-box {
        width: 50%;
        margin: auto;
        h4 {
          line-height: 200px;
          text-align: center;
          font-size: 150px;
          font-weight: 900;
          color: #fff;
          -webkit-text-stroke: 1.5px rgb(0, 0, 0);
        }
      }
    }
  }
  @media(max-width: 1080px){
    .banner {
      background-position: 0 0;
      .banner-box {
        display: none !important;
      }
    }
  }
  .container {
    text-align: left;
  }
  .anctors {
    button {
      min-width: 150px;
      font-size: 1.2rem;
    }
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .rwd-show {
    display: none;
  }
  @media screen and (max-width: 420px) {
    .rwd-hide {
      display: none !important;
    }
    .rwd-show {
      display: block;
    }
    .banner {
      height: 30vh;
      background-size: contain;
      background-image: url('../../assets/image/pic/banner_bg_toeic_top_SM_W.jpg');
    }
  }
</style>
