<template>
  <div class="video-box">
    <div class="d-flex">
      <div class="navbar_l pointer" @click.self="navbarToggle" :class="{'active_l': !toggle}">
        <ul class="list-group p-0">
          <li v-for="(item, index) in videoData" @click="goLesson(index)" :key="index"  class="list-group-item list-group-item-action m-0 px-0 pointer" :class="{'current' : current === index}">
            <img class="icon" v-if="item.type ==='live_course'"  src="../assets/image/icon&btn/icon_have_btn_function/btn_sideFunction_interactive.png" alt="">
            <img class="icon" v-if="item.type ==='video'" src="../assets/image/icon&btn/icon_have_btn_function/btn_sideFunction_video.png" alt="">
            <img class="icon" v-if="item.type ==='practice'" src="../assets/image/icon&btn/icon_have_btn_function/btn_sideFunction_test.png" alt="">
            <br>
            <span v-if="item.type ==='live_course'">
              互動課程
            </span>
            <span v-if="item.type ==='video'">
              名師講解
            </span>
            <span v-if="item.type === 'practice'">
              挑戰測驗
            </span>
          </li>
            <!-- <br><span v-if="item.type ==='video'">影片</span></li> -->
          <li @click="goPage" class="list-group-item list-group-item-action m-0 px-0 pointer"><img class="icon" src="../assets/image/icon&btn/icon_have_btn_function/btn_more.png" alt=""><br><span>看更多</span></li>
          <li @click="$router.go(-1)" class="list-group-item list-group-item-action m-0 px-0 pointer"><img class="icon" src="../assets/image/icon&btn/icon_have_btn_function/btn_sideFunction_home.png" alt=""><br><span>返回</span></li>
        </ul>
        <div class="collapse-icon" :class="{'rotate':!toggle}">
          <!-- {{videoData[0].data.path}} -->
          <font-awesome-icon @click.self="navbarToggle" class="pointer chevron" size="lg" :icon="['fa', 'chevron-right']"  style="color:rgba(0, 0, 0, .2)"/>
        </div>
      </div>
      <div class="video mx-auto" style="width:100%;max-width: 1280px!important;min-width: 320px!important;min-height:980px" >
        <div class="m-3" style="text-align:left">
          <img class="pointer" style="float:right;width:40px" v-if="favorite" @click="removeCollections(lessonId)" src="../assets/image/pic/btn_function_mark_on.png" alt="">
          <img class="pointer" style="float:right;width:40px" v-else @click="addCollections(lessonId)" src="../assets/image/pic/btn_function_mark_off.png" alt="">
          <img class="pointer rwd-more" style="float:right;width:40px;padding:5px" @click="goPage" src="../assets/image/icon&btn/icon_have_btn_function/btn_more.png" alt="">
          <h3>
            {{this.titleName.name}}
          </h3>
          <h6>{{this.titleName.name_en}}</h6>
          <div v-if="videoData.length>1" class="btn-group mt-2 rwd-list" role="group">
            <button type="button" class="btn btn-outline-secondary" :class="{'active' : current === 0}" @click="goLesson(0)">互動課程</button>
            <button type="button" class="btn btn-outline-secondary" :class="{'active' : current === 1}" @click="goLesson(1)">名師講解</button>
          </div>
        </div>
        <div v-for="(item, index) in videoData" :key="index" >
          <div>
            <div v-if="lesson && current===index">
              <live-player v-on:updatedVideoState="updatedVideoState" :articleImg="cover" v-if="item.type === 'live_course' || item.type === 'practice'" :liveData="lesson" :sectionId="sectionId" />
            </div>
            <player style="width:100%; background:black; height:50vh" v-on:updatedVideoState="updatedVideoState" v-if="item.type ==='video'&& current===index" class="m-auto" :url="item.data.path"/>
          </div>
          <practice :sectionId="sectionId" style="width:85%" v-if="item.type ==='practice'&& current===index" class="page mx-auto py-5" :setNoticePage="true" :quests="JSON.parse(videoData[current].data.questions).practices" v-on:score="handlerPracticeScore" :passRate="config.practice ? config.practice.passRate : -1">
          </practice>
        </div>
      </div>
    </div>
    <!-- <div v-if="lesson.modules[0]">
      <Assist :assistData="lesson.modules[0].vocabularies" />
    </div> -->
  </div>
</template>

<script>
import api from '../store/api'
import LivePlayer from 'live-player'
import Assist from '../components/Assist'
import Player from 'live-player/dist/Player'
import Practice from 'live-player/dist/Practice'
import Exam from 'live-player/dist/Exam';
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CourseLesson',
  data () {
    return {
      cover: null,
      favorite: false,
      timer: null,
      time: 0,
      toggle: true,
      lesson: null,
      current: 0,
      period: 60,
      idle: 10,
      parentId: 0,
      state: 'stop'
    }
  },
  components: {
    LivePlayer,
    Player,
    Practice,
    Assist,
    Exam
  },
  props: {
    config: {
      type: Object,
      required: false,
      default: () => {
        return {
          practice: {
            noticePage: false,
            passRate: -1
          }
        }
      }
    },
    idleTime: {
      type: Number,
      required: true
    },
  },
  computed: {
    ...mapGetters([
      'videoData',
      'titleName'
    ]),
    sectionId () {
      return this.videoData[this.current].id
    },
    lessonId () {
      return this.$route.params.lesson
    }
  },
  watch: {
    current () {
      this.recordTime(this.time)
      this.time = 0
      this.getLesson(this.$route.params.courseId)
    },
    time (val) {
      if (val === this.period) {
        this.recordTime(val)
        this.time = 0
      }
    },
    state (val) {
      if (val === 'playing') {
        this.$emit('isIdle', 'playing')
      } else {
        this.$emit('isIdle', 'stop')
      }
    }
  },
  async beforeDestroy () {
    this.recordTime(this.time)
    this.$emit('isIdle', 'stop')
    clearInterval(this.timer)
  },
  async mounted () {
    this.scrollTop()
    this.getLessonId(this.lessonId)
    this.timer = setInterval(this.countUp, 1000)
    await this.getVideo(this.$route.params.lesson)
    if (this.videoData[this.current].type === 'live_course') {
      this.getLesson(this.$route.params.courseId)
    }
  },
  methods: {
    ...mapActions([
      'getVideo'
    ]),
    navbarToggle () {
      this.toggle = !this.toggle
    },
    async getLesson (id) {
      const { data } = await api.get(`/contents/lesson/${id}`)
      if (data.status === 'OK') {
        this.lesson = data.data
      }
    },
    goLesson (id) {
      this.current = id
    },
    updatedVideoState (val) {
      this.state = val
    },
    goPage () {
      this.$router.push({ name: 'Course', params: { channel: this.parentId } })
    },
    handlerPracticeScore (score) {
      this.$emit('practiceScore', score)
    },
    countUp() {
      if (this.state === 'playing') {
        this.time ++
      } else {
        if (this.idleTime < this.idle*60  ) {
          this.time ++
        } else if (this.idleTime === this.idle*60) {
          this.$swal({
            title: `Hello同學，歡迎繼續上課🙂`,
            text: `溫馨提醒:您已閒置超過${this.idle}分鐘，請點擊下方按鈕回到課程`,
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
        }
      }
    },
    async recordTime (val) {
      const options = { headers: {'showLoading': false }}
      const fileData = new window.FormData()
      fileData.append('seconds', val)
      const { data } = await api.post(`records/section/${this.sectionId}/time`, fileData, options)
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        this.favorite = data.data.is_favorite
        this.parentId = data.data.package_course.id
        this.cover = data.data.cover
        return data.data
      }
    },
    async addCollections (el) {
      await this.getLessonId(el).then((val) => {
        try {
          const json = {
            banner: val.package_course.banner,
            view_id: val.sections[0].data.view_id||val.sections[0].id
          }
          const fileData = new window.FormData()
          fileData.append('json', JSON.stringify(json))
          fileData.append('view_id', val.sections[0].data.view_id||val.sections[0].id)
          fileData.append('view_id', val.sections[0].data.view_id||val.sections[0].id)
          api.post(`collections/course/${el}`, fileData).then((res)=> {
            if (res.status === 200) {
              this.getLessonId(el)
            }
          })
        } catch (error) {
          console.error(error.response.data)
        }
      })
    },
    async removeCollections (el) {
      await this.getLessonId(el).then((val) => {
        try {
          api.delete(`collections/course/${el}`).then((res)=> {
            if (res.status === 200) {
              this.getLessonId(el)
            }
          })
        } catch (error) {
          console.error(error.response.data)
        }
      })
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
        if (currentScroll > 0) {
          window.scrollTo(0, 0)
        }
      }
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/styles/LivePlayer.css';
@import "../assets/styles/color.scss";
  .video-box {
    min-height: calc(100vh - 113px) !important;
  }
  .navbar_l {
    position: relative;
    min-height: calc(100vh - 113px)  !important;
    border: 1px solid rgb(207, 205, 205);
    ul {
      text-align: center;
      padding: 10px;
      white-space:nowrap;
      list-style-type: none;
      overflow: hidden;
      li {
        font-weight: 800;
        margin: 10px 0;
      }
    }
    width: 150px;
    background-color: rgb(255, 255, 255) !important;
    transition: all .5s cubic-bezier(0.25,0.1,0.25,1);
    opacity: 1;
  }
  .active_l {
    width: 65px;
    span {
      opacity: 0;
    }
  }
  .icon {
    width: 35px;
  }
  .hide {
    display: none;
  }
  .rwd-list {
    display: none;
  }
  .collapse-icon {
    position: absolute;
    transition: all .5s cubic-bezier(0.25,0.1,0.25,1);
    top:50%;
    right: 5%;
    transform: translate(-50%, -50%);
    transform: rotate(900deg);
  }
  .rotate {
    transform: rotate(0deg);
  }
  .current  {
    background-color: rgb(238, 238, 238) !important;
  }
  .rwd-more {
    display: none;
  }
  @media (max-width: 480px) {
    .navbar_l {
      display: none;
    }
    .rwd-list {
      button:active,button:focus {
        outline: none !important;
        box-shadow: none !important;
      }
      display: flex;
    }
  }
  @media (max-width: 480px) {
    .rwd-more {
      display: block;
    }
  }
</style>
