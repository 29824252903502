<template>
  <div class="container mx-auto" style="margin:130px 0">
    <div class="d-flex mb-3" v-if="showList">
      <span v-for="item in dailySentencesList">
        <button type="button" :class="{'active': dailySentencesType === item.id}" @click="selectType(item.id)" class="btn btn-sm btn-outline-danger mr-2">{{ item.name }}</button>
      </span>
    </div>
    <router-view :sentencesType="dailySentencesType"></router-view>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import apiLibrary from '../store/library/api'
export default {
  name: 'Daily',
  data () {
    return {
      dailySentencesList: [],
      dailySentencesType: 0
    }
  },
  mounted () {
    this.getDailySentencesList()
    this.scrollTop ()
  },
  computed : {
    showList () {
      switch (this.$route.name) {
        case 'Sentence':
          return true
        case 'modalList':
          return false
      }
    }
  },
  methods: {
    async getDailySentencesList () {
      const { data } = await apiLibrary.get('/v1/daily-sentences/categories/livelearningpro')
      this.dailySentencesList = data
      this.dailySentencesType = data[0].id
    },
    selectType (id) {
      this.dailySentencesType = id
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
</style>
