<template>
  <div>
    <div class="banner">
      <div class="banner-box" >
        <div class="title-box">
          <h2>{{$t('Personal.Updated_password.Change_password')}}</h2>
        </div>
      </div>
    </div>
    <div class="container my-3 layout">
      <div class="row align-self-center" v-if="authorized">
        <div class="col-12 p-3">
          <!-- <p>親愛的 {{user.enterprise_name}} {{user.name}}學員您好：</p>
          <p>請確認以下資料:</p>
          <br> -->
          <p>{{$t('Personal.Updated_password.Name')}}：<span class="bold">{{user.name}}</span></p>
          <p> {{$t('Personal.Updated_password.Email')}}：<span class="bold">{{user.email}} </span>({{$t('Personal.Updated_password.Change_account_remind')}})</p>
        </div>
        <div class="col-12">
          <form class="p-3" style="background-color:#FFF3E3;">
            <div class="form-group" v-if="authorized">
              <label class="bold">{{$t('Personal.Updated_password.Current_password')}}</label>
              <input v-model="oldPassword"  :placeholder="($t('Personal.Updated_password.Current_password_remind'))" type="text" class="form-control">
            </div>
            <div class="form-group">
              <label class="bold">{{$t('Personal.Updated_password.New_password')}}</label>
              <input v-model="newPassword" :placeholder="($t('Personal.Updated_password.New_password_remind'))" type="password" class="form-control">
            </div>
            <div class="form-group">
              <label class="bold">{{$t('Personal.Updated_password.Confirm_password')}}</label>
              <input v-model="confirmPassword" type="password" :placeholder="($t('Personal.Updated_password.Reenter_password_remind'))" class="form-control">
            </div>
          </form>
          <button type="submit" :disabled="!confirm" @click="updatedPassword" class="btn btn-primary">{{$t('Personal.Updated_password.Save')}}</button>
        </div>
      </div>
      <div class="row align-self-center" v-else>
        <div class="col-12 p-3">
        </div>
        <div class="col-12">
          <form class="p-3" style="background-color:#FFF3E3;">
            <div class="form-group">
              <label class="bold"> {{$t('Personal.Updated_password.Email_remind')}}</label>
              <input v-model="email"  :placeholder="($t('Personal.Updated_password.Email_remind'))" type="text" class="form-control">
            </div>
            <div class="form-group">
              <label class="bold">{{$t('Personal.Updated_password.New_password')}}</label>
              <input v-model="newPassword" :placeholder="($t('Personal.Updated_password.New_password_remind'))" type="password" class="form-control">
            </div>
            <div class="form-group">
              <label class="bold">{{$t('Personal.Updated_password.Confirm_password')}}</label>
              <input v-model="confirmPassword" type="password" :placeholder="($t('Personal.Updated_password.Reenter_password_remind'))" class="form-control">
            </div>
          </form>
          <button type="submit" :disabled="!confirm" @click="resetPassword" class="btn btn-primary">{{$t('Personal.Updated_password.Save')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '../store/api'
import { mapGetters } from 'vuex'

export default {
  name: 'Updated-Password',
  data () {
    return {
      email: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirm: false,
      user: null,
    }
  },
  watch: {
  },
  mounted () {
    this.scrollTop()
    if (this.authorized) {
      this.getUser()
    }
  },
  computed : {
    ...mapGetters([
      'authorized'
    ]),
    token () {
      return this.$route.query.token
    }
  },
  watch: {
    confirmPassword (val) {
      if (val === this.newPassword && val !== '') {
        this.confirm = true
      } else {
        this.confirm = false
      }
    },
    newPassword (val) {
      if (val === this.confirmPassword && val !== '') {
        this.confirm = true
      } else {
        this.confirm = false
      }
    }
  },
  methods: {
    async getUser () {
      const { data } = await api.get('/me')
      this.user = data
    },
    goPage (el) {
      this.$router.push({ name: el })
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    async updatedPassword () {
      const self = this
      try {
        await api.post(`update_password`, {
          old_password: this.oldPassword,
          new_password: this.newPassword
        })
        this.$swal({
          title: '儲存成功',
          text: '下次登入請使用新密碼',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          self.$router.go(-1)
        }})
      } catch (error) {
        console.error(error.response.data)
        this.$swal({
          title: '修改密碼失敗',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    },
    async resetPassword () {
      const self = this
      try {
        await api.post(`password/reset`, {
          token: this.token,
          email: this.email,
          password: this.newPassword,
          password_confirmation: this.confirmPassword
        })
        this.$swal({
          title: '儲存成功',
          text: '下次登入請使用新密碼',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          self.$router.push({ name: 'Login' })
        }})
      } catch (error) {
        console.error(error.response.data)
        this.$swal({
          title: '修改密碼失敗',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .layout {
    overflow: hidden;
    min-height: 40vh;
  }
  .title-box {
    h2,h4 {
      font-weight: bolder;
      // color: #fff;
    }
    p {
      width: 80%;
      margin: auto;
      // color: #fff;
    }
  }
  .remind {
    color: red;
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/pic/banner_bg_welcome_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .banner-box {
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: auto;
    }
  }
  .clause {
    padding: 50px 0;
    .clause-box {
      h6{
        font-weight: bolder;
      }
    }
    .clause-box>ul {
      list-style-type: cjk-ideographic;
      li {
        margin: 20px 0;
      }
    }
    .red {
      color: red;
    }
  }
  .bold {
    font-weight: bolder;
  }
</style>
