<template>
  <div class="container">
    <div class="row" style="min-height:80vh">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ pageName }}</h3>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="(item, index) in listData" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <!-- <div class="my-auto">
                  <img v-if="!item.is_favorite" @click="addCollections(item)" class="pointer" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_mark_off.png" alt="">
                  <img v-else @click="removeCollections(item)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                </div> -->
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.date).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <div>
                  <img style="width:100px; " :src="item.cover" alt="" srcset="">
                </div>
                <div class="my-auto ml-2">
                  <p class="p-0 my-1 main-title">{{item.name}}</p>
                  <p class="p-0 my-1">{{item.name_en}}</p>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="goPage(item.id)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination'
import api from '../store/api'
export default {
  name: 'ModalList',
  components: {
    Pagination
  },
  data () {
    return {
      listData: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  mounted () {
    this.getListData()
  },
  computed: {
    pageName () {
      switch (this.$route.params.courseId) {
        case '280':
          return '老外帶你遊臺灣'
        case '282':
          return '英文好好玩'
        case '278':
          return '最新話題字'
      }
    }
  },
  methods: {
    async getListData() {
      const params = {
        page: 1,
        pageSize: 10,
        sort: 'public_at',
        order: 'desc'
      }
      const { data } = await api.get(`/contents/package_courses/${this.$route.params.courseId}/courses`, { params })
      this.listData = data.data
    },
    async goPage (id) {
      await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getListData()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .content-box {
    // height: 100%;
    background-color: rgb(230, 230, 230);
    text-align: left;
    padding: 8px;
  }
</style>
