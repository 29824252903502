import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Channels from '../views/Channels.vue'
import Daily from '../views/Daily.vue'
import Sentence from '../components/Sentence.vue'
import News from '../components/News.vue'
import ModalList from '../components/ModalList.vue'
import Course from '../views/Course.vue'
import FirstLogin from '../views/FirstLogin.vue'
import UpdatedPassword from '../views/UpdatedPassword.vue'
import ChannelLesson from '../views/ChannelLesson.vue'
import CourseLesson from '../views/CourseLesson.vue'
import Login from '../views/Login.vue'
import Personal from '../views/Personal.vue'
import Examination from '../views/Examination.vue'
import ExamArea from '../components/ExamArea.vue'
import Record from '../components/Record.vue'
import Target from '../components/Target.vue'
import Collections from '../components/Collections.vue'
import Raiders from '../views/Vocabulary/Raiders.vue'
import RaidersIelts from '../views/Vocabulary/RaidersIelts.vue'
import VocabulariesLayout from '../views/Vocabulary/Layout.vue'
import Vocabularies from '../views/Vocabulary/Vocabularies.vue'
import VocabulariesList from '../views/Vocabulary/VocabulariesList.vue'
import DetailAnswer from '../views/DetailAnswer.vue'
import Director from '../views/Director/Director.vue'
import Overview from '../views/Director/Overview.vue'
import PersonalDetail from '../views/Director/PersonalDetail.vue'
import Talk from '../views/Talk.vue'
import Search from '../views/Search.vue'
import Writing from '../views/Writing/Layout.vue'
import WritingList from '../views/Writing/WritingList.vue'
import WritingEditor from '../views/Writing/WritingEditor.vue'
import NewsLetter from '../views/NewsLetter.vue'
import AiHelperLayout from '../views/AiHelper/Layout.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/first-login',
    name: 'First-login',
    component: FirstLogin,
    meta: { requiresAuth: false }
  },
  {
    path: '/reset_password',
    name: 'Reset-password',
    component: UpdatedPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/updated-password',
    name: 'Updated-password',
    component: UpdatedPassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/daily',
    name: 'Daily',
    component: Daily,
    children: [
      { path: 'sentence', name: 'Sentence', component: Sentence },
      { path: 'news', name: 'News', component: News },
      { path: 'list/:courseId', name: 'modalList', component: ModalList }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/channels/:channel',
    name: 'Channels',
    component: Channels,
    meta: { requiresAuth: true }
  },
  {
    path: '/lesson/:lessonId',
    name: 'ChannelLesson',
    component: ChannelLesson,
    meta: { requiresAuth: true }
  },
  {
    path: '/course/:channel',
    name: 'Course',
    component: Course,
    meta: { requiresAuth: true }
  },
  {
    path: '/course-lesson/:lesson/:courseId',
    name: 'CourseLesson',
    component: CourseLesson,
    meta: { requiresAuth: true }
  },
  {
    path: '/toeic/raiders',
    name: 'Raiders',
    component: Raiders,
    meta: { requiresAuth: true }
  },
  {
    path: '/ielts/raiders',
    name: 'RaidersIelts',
    component: RaidersIelts,
    meta: { requiresAuth: true }
  },
  {
    path: '/biz-talk',
    name: 'Biz-talk',
    component: Talk,
    meta: { requiresAuth: true }
  },
  {
    path: '/newsletter',
    name: 'NewsLetter',
    component: NewsLetter,
    meta: { requiresAuth: true }
  },
  {
    path: '/writing',
    name: 'Writing',
    component: Writing,
    children: [
      { path: '', name: 'WritingList', component: WritingList },
      { path: 'editor/:id', name: 'WritingEditor', component: WritingEditor }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/toeic/vocabularies',
    name: 'Toeic-Vocabularies',
    component: VocabulariesLayout,
    children: [
      { path: '', name: 'Toeic-Vocabularies', component: Vocabularies },
      { path: ':id/:subId', name: 'Toeic-List', component: VocabulariesList }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/ielts/vocabularies',
    name: 'ielts-Vocabularies',
    component: VocabulariesLayout,
    children: [
      { path: '', name: 'Ielts-Vocabularies', component: Vocabularies },
      { path: ':id/:subId', name: 'Ielts-List', component: VocabulariesList }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/email/vocabularies',
    name: 'email-Vocabularies',
    component: VocabulariesLayout,
    children: [
      { path: '', name: 'email-Vocabularies', component: Vocabularies },
      { path: ':id/:subId', name: 'email-List', component: VocabulariesList }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
    meta: { requiresAuth: true },
    children: [
      { path: 'target', name: 'Target', component: Target },
      { path: 'collections', name: 'Collections', component: Collections },
      { path: 'exam', name: 'Exam-area', component: ExamArea },
      { path: 'record', name: 'Record', component: Record }
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: route => ({ query: route.query.q }),
    meta: { requiresAuth: true }
  },
  {
    path: '/examination/:examId/:viewId',
    name: 'Examination',
    component: Examination,
    meta: { requiresAuth: true }
  },
  {
    path: '/detailAnswer/:examId/:viewId/:recordId?',
    name: 'DetailAnswer',
    component: DetailAnswer,
    meta: { requiresAuth: true }
  },
  {
    path: '/director',
    name: 'Director',
    component: Director,
    children: [
      { path: 'overview/:type/:groupId?', name: 'Overview', component: Overview },
      { path: 'personal-detail/:type/:groupId?', name: 'PersonalDetail', component: PersonalDetail }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: '',
    component: AiHelperLayout,
    children: [
      { path: 'intro', name: 'ai_assistantIntro', component: () => import('@/views/AiHelper/Intro.vue') },

      { path: 'generate/:aiType', name: 'ai_assistantMain', component: () => import('@/views/AiHelper/Speech.vue') },

      { path: 'AI/:promptId?', name: 'ChatAI', component: () => import('@/views/AiHelper/ChatContent.vue') },

      { path: 'chat_history', name: 'chat_history', component: () => import('@/views/AiHelper/ChatHistory.vue') },

      { path: 'conversation_rating', name: 'conversation_rating', component: () => import('@/views/AiHelper/ChatRate.vue') }
    ],
    meta: { requiresAuth: true }
  },
  { path: '*', redirect: { name: 'Home' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
