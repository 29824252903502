<template>
  <div class="aiHelper d-flex">
    <div class="side-bar p-2 p-lg-4 d-flex flex-column w-100 justify-content-between">
      <aside class="sidenav">
        <div class="mb-4 d-none d-lg-block">
          <img class="d-block object-contain h-auto w-100" src="@/assets/image/ai/AI_LOGO.png" alt="">
        </div>
        <ul class="sidenav__list text-white list-none p-0">
          <li class="sidenav__list_item mb-2">
            <router-link :to="{ 'name': 'ai_assistantIntro' }" class="m-0 d-flex align-items-center p-2" :class="{ 'active': $route.name === 'ai_assistantIntro' }">
              <img src="@/assets/image/ai/Subtract.png" alt="" class="mr-2">使用指南
            </router-link>
          </li>
          <li class="sidenav__list_item mb-2">
            <router-link :to="{ 'name': 'ai_assistantMain', 'params': { aiType: 'letter' } }" class="m-0 d-flex align-items-center p-2" :class="{ 'active': $route.name === 'ai_assistantMain' && $route.params.aiType === 'letter' }">
              <img src="@/assets/image/ai/Subtract.png" alt="" class="mr-2">商用英文書信小幫手
            </router-link>
          </li>
          <li class="sidenav__list_item mb-2">
            <router-link :to="{ 'name': 'ai_assistantMain', 'params': { aiType: 'speech' } }" class="m-0 d-flex align-items-center p-2" :class="{ 'active': $route.name === 'ai_assistantMain' && $route.params.aiType === 'speech' }">
              <img src="@/assets/image/ai/Subtract.png" alt="" class="mr-2">英文演講稿小幫手
            </router-link>
          </li>
          <li class="sidenav__list_item" v-for="(item, index) in menuData" :key="index" :class="{ 'mb-2': index < menuData.length - 1 }">
            <router-link :to="{ 'name': item.route_name, 'params': { promptId: item.promptId } }" class="m-0 d-flex align-items-center p-2"
            :class="{ 'active': ($route.name === 'ChatAI' &&  item.promptId === $route.params.promptId) }">
              <img src="@/assets/image/ai/Subtract.png" alt="" class="mr-2">{{ item.name }}
            </router-link>
          </li>
        </ul>
      </aside>

    </div>
    <div class="contain-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import api from '@/store/api'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'aiHelper',
    data () {
      return {
        selectedIdx: 1,
        menuData: [
          // {
          //   name: '使用指南',
          //   route_name: 'ai_assistantIntro',
          //   params: ''
          // }
          // {
          //   name: '商用英文書信小幫手',
          //   route_name: 'ai_assistantMain',
          //   params: 'letter'
          // },
          // {
          //   name: '英文演講稿小幫手',
          //   route_name: 'ai_assistantMain',
          //   params: 'speech'
          // },
          // {
          //   name: '口說練習小幫手',
          //   route_name: 'ChatAI',
          //   params: ''
          // }
        ]
      }
    },
    watch: {
      // '$route': {
      //   async handler (newVal, oldVal) {
      //     if (newVal && (newVal !== oldVal)) {
      //       await this.getMenuIdx()
      //     }
      //   },
      //   deep: true,
      //   immediate: true
      // }
    },
    computed: {
      ...mapGetters([
        'remainPoints'
      ]),
    },
    mounted () {
      this.getMenu()
      // this.getMenuIdx()
      this.doGetPoints()
    },
    methods: {
      ...mapActions({
        doGetPoints: 'getRemainPoints'
      }),
      getMenu () {
        let self = this

        let path = `ai/categories`

        api.get(path)
        .then((res) => {
          console.log(res)
          for (const [key, value] of Object.entries(res.data)) {
            self.menuData.push(
              {
                name: `${key}`,
                route_name: 'ChatAI',
                promptId: `${value}`
              }
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
      },
      // changeRouter () {
      //   let self = this
      //   let routerName = self.menuData[self.selectedIdx].value
      //   let routerParams = self.menuData[self.selectedIdx].params
      //   self.$router.push({ name: routerName, params: { aiType: routerParams } })
      // }
      // getMenuIdx () {
      //   let self = this

      //   self.selectedIdx = self.menuData.findIndex(x => x.value === self.$route.name)
      // }
    }
  }
</script>
<style lang="scss">
  .aiHelper {
    overflow: hidden;
    text-align: left;
    // flex-wrap: wrap;
    @media (max-width: 769px) {
      flex-wrap: wrap;
    }
    .side-bar {
      background: #0E103E;
      max-width: 260px;
      width: 100%;
      @media (max-width: 769px) {
        max-width: 100%;
        // display: none !important;
      }
      ul {
        list-style: none;
        list-style-position: inside;
        li {
          p {
            font-weight: 700;
            font-size: 1.125rem;
          }
          a {
            color: white;
            // font-weight: 700;
            font-size: 1.125rem;
            border-radius: 8px;
            white-space: nowrap;
            &:hover {
              background: rgb(255, 255, 255, 0.2);
              text-decoration: none;
            }

          }
          .active {
            background: linear-gradient(180deg, #F94F39 0%, #FF763C 100%);
          }
        }
      }
    }
    .side-bar-mobile {
      background: #0E103E;
      width: 100%;
      @media (min-width: 769px) {
        display: none;
      }
      .custom-dropdown {
        position: relative;
      }
      .custom-dropdown select {
        width: 100%;
        background: #0E103E;
        color: #fff;
        padding: .5em;
        padding-right: 2.5em;
        border: 0;
        margin: 0;
        border-radius: 6px;
        border: 1px solid var(--default-white, #FFF);
        text-indent: 0.01px;
        text-overflow: '';
        -webkit-appearance: button;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
      }

      .custom-dropdown::after {
        position: absolute;
        pointer-events: none;
        color: rgba(255, 255, 255, 1);
        content: "\25BC";
        height: 1em;
        font-size: 0.625em;
        line-height: 1;
        right: 1.2em;
        top: 40%;
      }
    }
    .contain-main {
      background: #eaedf2;
      min-height: 100vh;
      width: 100%;
      @media (min-width: 1023px) {
        width: calc(100vw - 260px);
      }
    }
    p {
      margin: 0;
      white-space: normal;
    }
  }
</style>
