<template>
  <div>
    <div class="banner">
      <div class="banner-box">
        <div>
          <h2>
            真人口說教室
          </h2>
          <p>
            為提供學員更完善的服務，特別規劃真人口說教室，
          </p>
          <p>
            嚴選中外籍師資，線上真人即時互動，輕鬆提升口說實力。
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="my-3" style="text-align:center">
        <button @click="goPage" style="width:120px" type="button" class="btn btn-primary">Go</button>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex my-3">
            <h3 class="title pr-3">服務特色</h3>
            <div class="line"></div>
          </div>
          <div>
            <div>
              <!-- <p>工作上常需要與國外客戶聯繫，希望更有效提升英語會話能力?</p> -->
              <img class="my-5" style="width:100%" src="../assets/image/home/from_speak_info_1_4x.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex my-3">
            <h3 class="title pr-3">上課流程</h3>
            <div class="line"></div>
          </div>
          <div>
            <!-- <p>
              工作上常需要與國外客戶聯繫，希望更有效提升英語會話能力?
            </p> -->
            <img style="width:100%" class="my-5" src="../assets/image/home/from_speak_info_2_4x.png" alt="">
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex my-3">
            <h3 class="title pr-3">FAQ</h3>
            <div class="line"></div>
          </div>
          <div class="faq">
            <ul>
              <li>
                <p class="faq-title">什麼是真人口說教室？</p>
                <p>學英語就是要開口說！為提供學員更多元學習服務，特別設立真人口說教室，嚴選培訓中外籍頂尖師資， 一對一線上真人即時互動，全面提升您的語言實力。</p>
              </li>
              <li>
                <p class="faq-title">如何預約課程？</p>
                <p>請點擊「Go」進入真人口說平台，預約您的專屬課堂。</p>
              </li>
              <li>
                <p class="faq-title">目前開課時段？</p>
                <p>週一至週五13:00-14:00、20:00~22:00，週六至週日14:00-22:00（國定假日依人事行政局公告停課）。 </p>
              </li>
              <li>
                <p class="faq-title">取消預約好的課程，該如何取消？</p>
                <ul style="list-style-type: none;" class="p-0">
                  <li class="p-0">開課前12小時：所有課程均可預約與取消（較建議此方式，保留課程取消彈性）。</li>
                  <li class="p-0">開課前6小時：開課前6小時也能預約，但預約後不能取消。</li>
                </ul>
              </li>
              <li>
                <p class="faq-title">是否需安裝上課視訊軟體？</p>
                <p>不用，本系統自動搭載Google Meet，即開即用，免安裝軟體。 </p>
              </li>
              <li>
                <p class="faq-title">上課前多久要登入？</p>
                <p>課前5分鐘登入即可。 </p>
              </li>
              <li>
                <p class="faq-title">線上上課時可以關閉我自己的影像嗎？</p>
                <p>可以，您可以關閉個人影像，您仍可看到老師的教學影像。</p>
              </li>
              <li>
                <p class="faq-title">建議的學習方式?</p>
                <p>建議課前先下載教材預習，課中與老師互動，課後20天可以進入課表觀看當天上課錄影檔複習。</p>
              </li>
              <li>
                <p class="faq-title">課程取消規則為何？</p>
                <ul style="list-style-type: none;" class="p-0">
                  <li class="p-0">（1）如需取消上課，最遲請於課前12小時進入系統取消該堂預約課程。</li>
                  <li class="p-0">（2）開課前12小時以內之課程，由於課程已準備中，恕無法取消。 </li>
                </ul>
              </li>
              <li>
                <p class="faq-title">上課建議網路頻寬？</p>
                <p>最低下載/上傳建議1.5M以上，當下流暢度會依網路環境而定，通常建議頻寬越大越好。</p>
              </li>
              <li>
                <p class="faq-title">聽到有雜音或迴音？</p>
                <ul class="p-0" style="list-style-type: none;">
                  <li class="p-0">（1） 如為外接耳機麥克風，請先確認耳機麥克風是否有接緊接好。</li>
                  <li class="p-0">（2） 請注意單孔或雙孔耳機麥克風僅能接相符的電腦耳機孔進行運作。</li>
                  <li class="p-0">（3） 請確認麥克風是否有捲線問題或調整麥克風位置試試看。</li>
                  <li class="p-0">（4） 迴音的部分請確認教室視窗是否有重複開啟或附近有人也在上課。</li>
                </ul>
              </li>
              <li>
                <p class="faq-title">客服窗口該如何聯繫？</p>
                <p>技術客服：02-7730-9567 （週一至週日09:00-22:00）</p>
                <p>業務客服：02-25782626#219 （週一至週五09:00-18:00）</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '../store/api'

export default {
  name: 'Talk',
  components: {

  },
  computed: {

  },
  data () {
    return {
      url: ''
    }
  },
  mounted () {
    this.getUrl()
    this.scrollTop()
  },
  methods: {
    async getUrl () {
      const { data } = await api.get(`/connect/biztalk`)
      if (data.status === 'Success') {
        this.url = data.url
      }
    },
    goPage () {
      window.open(this.url, '_blank')
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";

  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    // width: 100%;
    width: 98%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/home/banner_bg_biztalk_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .banner-box {
      padding: 60px 0 10px 0;
      h2 {
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }
      p {
        color: rgb(47, 161, 255);
        font-weight: 600;
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
  .faq {
    margin: 0 0 120px 0;
  }
  .faq>ul {
    margin: 0;
    list-style-type: decimal;
    li {
      padding: 10px;
      p {
        margin: 0;
      }
    }
    .faq-title {
      font-weight: 800;
    }
  }
  @media screen and (max-width: 450px) {
    .banner-box {
      p {
        font-size: .8rem !important;
      }
    }
  }
</style>
