import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=2c9a3585&scoped=true"
import script from "./Layout.vue?vue&type=script&lang=js"
export * from "./Layout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9a3585",
  null
  
)

export default component.exports