<template>
  <footer class="footer">
    <popUp v-if="toggle" v-on:doClose="close">
      <div slot="info" class="info-main" >
        <h4>免責聲明及AI生成內容著作權聲明</h4>
        <br/>
        <p>
          本網站的部分內容和功能由生成式人工智慧（Generative AI）技術自動生成。儘管我們致力於提供準確和有價值的學習資源，AI生成的內容可能存在不完整、不準確、或非預期的結果。任何AI 生成的回應或內容，使用者應自行核實其準確性，並謹慎使用。
        </p>
        <h6>免責聲明：</h6>
        <ul>
          <li>內容準確性：任何生成式 AI 所提供的內容僅供參考，本網站無法保證其完全正確或為最新資訊，使用者須自行予以核實和調整。使用者對於信賴或使用生成之內容所產生的任何形式之糾紛、損失或損害，本網站及其運營方不承擔任何法律責任。</li>
          <li>專業建議替代：生成式 AI的回應或內容不應被視為專業建議、法律意見或權威性答案，使用者應根據實際情況尋求獨立之法律意見或其他專業意見。</li>
          <li> 技術限制：生成式AI 具有其技術上的侷限，生成的回應或內容可能不完全符合使用者需求，並可能會在某些情境下產生不恰當或不妥適的結果。本網站及其運營方無法保證生成內容之完整性或適用性。</li>
        </ul>
        <h6>智慧財產權聲明：</h6>
        <ul>
          <li>AI 生成之內容：本網站所使用的生成式 AI 是基於公共訓練數據及開放技術開發，AI 生成的內容不受特定來源或專有數據的控制。由於生成內容的自動性，本網站及其運營方無法對其內容進行完整之第三方智慧財產權檢查或控管，亦無法保證使用者能夠對AI 生成的內容主張著作權等智慧財產權利。</li>
          <li>第三方資料的潛在引用：AI 生成的內容有時可能無意識地模仿或引用已存在的資料或作品。若發現有侵犯第三方著作權等智慧財產權的情況，請立即通知本網站，本網站將迅速採取行動，包括移除相關內容。</li>
        </ul>
        <h6> 使用者責任：</h6>
        <ul>
          <li> 使用者在本網站上創建或傳輸之任何內容，應確保不違反任何法律法規，不侵害他人的權利。</li>
          <li> 使用者有責任確保其使用從本網站獲得的 AI 生成內容均符合著作權等智慧財產權之相關法律，並在使用該內容（如轉載、分享、或商業應用）時，取得必要的授權或許可，使用者違反相關法令或不當使用AI 生成內容所產生之任何損失或損害，本網站及其運營方不承擔任何法律責任。</li>
        </ul>
        本網站保留隨時修改本免責聲明及智慧財產權聲明之權利，使用者應定期查閱免責聲明，以瞭解相關政策及任何變更。
      </div>
    </popUp>
    <div class="container" :class="{'footer-rwd': this.$route.name === 'CourseLesson'}">
      <div class="row py-5">
        <div class="row justify-content-around col-md-8">
          <div class="col-sm-9 col-9">
            <h4>{{ $t('Footer.About') }}</h4>
            <p>
              {{ $t('Footer.About_content') }}
            </p>
            <br>
            <span class="pointer" @click="toggle=true">免責聲明及AI生成內容著作權聲明</span>
          </div>
          <div class="col-sm-3 col-3">
            <h4>
              相關連結
            </h4>
            <p>
              <a target="_blank" href="https://www.facebook.com/milearninghk/">Facebook</a>
            </p>
            <p>
              <a target="_blank" href="http://www.mi-learning.com/">官方網站</a>
            </p>
          </div>
        </div>
        <div class="footer-title col-md-4 m-auto text-center">
          <h2>MI Learning</h2>
          <div style="height:180px;">
            <img style="width:150px" src="../assets/QR-code.png" alt="">
          </div>
          <select v-model="locale" @change="setLocale(locale)">
            <option value="en">English</option>
            <option value="tw">繁體中文</option>
            <option value="cn">简体中文</option>
          </select>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import PopUp from '@/components/PopUp.vue'
// import store from '../store';
export default {
  name: 'Footer',
  data () {
    return {
      toggle: false,
      locale: window.localStorage.getItem('locale') || 'tw'
    }
  },
  components: {
    PopUp
  },
  mounted () {
  },
  methods: {
    setLocale (locale) {
      window.localStorage.setItem('locale', locale || 'tw')
      this.locale = locale
      this.$store.commit("setLocal")
      this.scrollTop()
      return window.history.go(0)
    },
    close (close) {
      this.toggle = close
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
@import "../assets/styles/custom.scss";
  .footer {
    background-color: $main-color;
    min-height: 150px;
    color: $white-color;
    position: relative;
    .footer{
      text-align: left;
    }
    h4 {
      text-align: left;
      font-weight: bolder;
    }
    p {
      text-align: left;
    }
    .footer-title {
      h3 {
        @include text(5rem, .2rem, white);
      }
    }
  }
  .info-main {
    color: #000;
  }
  a,a:hover {
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    .footer-rwd {
      display: none;
    }
  }
</style>
