<template>
  <div style="overflow: hidden;">
    <div class="banner">
      <div class="banner-box" >
        <div class="title-box">
          <h2>Hi，歡迎加入LiveABC線上英語課程行列!</h2>
          <h4 class="mt-4">
            很高興見到你，讓我們一起提升英文的聽說讀寫能力!一起加油吧!
          </h4>
        </div>
      </div>
    </div>
    <div class="container my-3">
      <div class="row" v-if="current === 'updated'">
        <div class="col-12 p-3">
          <p>親愛的 {{user.enterprise_name}} {{user.name}}學員您好：</p>
          <p>請確認以下資料:</p>
          <br>
          <p>{{$t('Personal.Updated_password.Name')}}：<span class="bold">{{user.name}}</span></p>
          <p> {{$t('Personal.Updated_password.Email')}}：<span class="bold">{{user.email}} </span>({{$t('Personal.Updated_password.Change_account_remind')}})</p>
        </div>
        <div class="col-12">
          <form class="p-3" style="background-color:#FFF3E3;">
            <div class="form-group">
              <label class="bold" for="exampleInputEmail1">{{$t('Personal.Updated_password.Current_password')}}</label>
              <input v-model="oldPassword"  :placeholder="($t('Personal.Updated_password.Current_password_remind'))" type="text" class="form-control">
            </div>
            <div class="form-group">
              <label class="bold" for="exampleInputEmail1">{{$t('Personal.Updated_password.New_password')}}</label>
              <input v-model="newPassword" :placeholder="($t('Personal.Updated_password.New_password_remind'))" type="password" class="form-control">
            </div>
            <div class="form-group">
              <label class="bold" for="exampleInputEmail1"> {{$t('Personal.Updated_password.Confirm_password')}}</label>
              <input v-model="confirmPassword" type="password" :placeholder="($t('Personal.Updated_password.Reenter_password_remind'))" class="form-control">
            </div>
          </form>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" v-model="checked">
            <label class="form-check-label" >我同意
              <a href="javascript:void();" @click="goClause('member')"> LiveABC之會員條款</a>
              與
              <a href="javascript:void();" @click="goClause('privacy')"> 隱私權條款</a>
            </label>
          </div>
          <button type="submit" :disabled="!confirm" @click="updatedPassword" class="btn btn-primary">{{$t('Personal.Updated_password.Save')}}</button>
        </div>
      </div>
      <div class="row"  v-if="current === 'doExam'">
        <div class="col-12">
          <div class="py-3">
            <br>
            <p class="bold">歡迎加入LiveLearning Pro 課程</p>
            <p>在正式上課前，建議先參加學前測驗，已檢測您的英文程度</p>
            <br>
            <p>本測驗模擬最新雅思測驗題型，包含:</p>
            <p>聽力測驗50題，滿分495分</p>
            <p>閱讀測驗50題，滿分495分</p>
            <br>
            <p>考試時間約1小時，請以電腦考試，請問您要現在進行學前測驗嗎?</p>
          </div>
          <button type="button" class="btn btn-danger mr-3" @click="goPage('Exam-area')">開始測驗</button>
          <button type="button" class="btn btn-danger" @click="goPage('Home')">返回課程首頁</button>
        </div>
      </div>
      <div class="row clause" v-if="current === 'clause'">
        <div class="clause-box col-12" v-if="clause === 'member'">
          <h3>
            「希伯崙股份有限公司」所屬網站(LiveABC互動英語教學集團)會員服務條款
          </h3>
          <ul>
            <li>
              <h6>認知與接受條款</h6>
              <ul>
                <li>「希伯崙股份有限公司」(以下稱本公司)所經營之網站「LiveLearning PRO」網站(以下稱LiveABC網站)，將依據本會員服務條款（以下稱本服務）提供LiveABC網站各項服務內容。當您開始使用本服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有内容。並完全接受本服務現有與未來衍生的服務項目及內容。本公司有權於任何時間修改或變更本服務條款之內容，修改後的服務條款內容將於公告三十日內公佈於LiveABC互動英語教學集團網站，並於使用服務時通知個別會員。如會員於任何修改或變更後，並同意繼續使用本服務者，將視為會員己閱讀、瞭解並同意接受該等修改或變更。</li>
                <li>若您為未滿二十歲，除應收受上述條款外，應確認您的法定代理人閱讀、瞭解並同意本會員服務條款之所有内容及其後修改變更後，方得使用或繼使用本服務。當您使用或繼續使用本服務時，需取得請未成年人提供法代同意書或由以專人電話回撥確認身分確認，即表示您的法定代理人已閱讀、瞭解並同意接受本會員服務條款之所有內容及其後修改變更。</li>
                <li>於提供課程學習、網路購物、行銷推廣、物流服務、帳務服務、客戶管理及服務，本公司將藉由加入會員或進行交易之手續，蒐集、處理、及利用您的個人資料，包括姓名、性別、生日、教育、職業、地址、e-mail、聯絡方式等，並對您發送課程活動訊息、商品優惠訊息等相關服務。為了確保會員之個人資料、隱私及消費者權益之保護，依個人資料保護法規定告知以下事項，本公司將以電郵、傳真、電話、簡訊或其他通知公告方式利用您所提供之資料（資料類別C001、C002、C003、C011等各項類別相關資料）。</li>
                <li>除會員有反對之表示，LiveABC集團網站得於會員入會期間內，利用會員之個人資料。LiveABC集團網站對所蒐集之資料依法保密。會員有請求停止蒐集、處理、利用或請求刪除之權利，辦理方式為電話通知客服中心處理（02）25782626#219。</li>
                <li>
                  您同意於下列情形發生時，得依法公開您的個人資訊：
                  <ul>
                    <li>法律明文規定。</li>
                    <li>為維護國家安全或增進公共利益所必要。</li>
                    <li>為免除當事人之生命、身體、自由或財產上之危險。</li>
                    <li>為防止他人權益之重大危害。</li>
                  </ul>
                </li>
                <li>您得自由選擇提供之個人資料，若您提供之資料不正確、不完整或未隨時更正或補充，可能會無法收到刊物、優惠活動訊息等。</li>
                <li>會員有權對個人資料行使以下權利，包含查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除，如欲行使上述權利，可與本公司客服連絡進行申請。</li>
              </ul>
            </li>
            <li>
              <h6>
                會員遵守（注意）事項
              </h6>
              <p class="red">
                您註冊為會員或於使用本服務之同時，會員將遵守以下義務：
              </p>
              <ul>
                <li>於本服務註冊表詢問事項，提供您本人正確、最新及完整的資料，且不得以第三人名義註冊為會員。每位會員僅能註冊及登錄一個帳號，不可重覆註冊及登錄。</li>
                <li>為確保會員個人資料為正確、最新及完整，會員應維持並即時更新個人資料。</li>
                <li>若會員有提供任何錯誤或不實資料、或未按指示提供資料、或欠缺必要資料、或有重覆註冊帳號等情事時，經本公司確認屬實後，應通知會員立即修正資料，以確保會員使用權益。</li>
                <li>會員須妥善保存自己的帳號及密碼，不得將密碼告訴第三者，甚或提供第三者使用。會員的帳號、密碼及會員權益均僅供會員個人使用及享有，在任何情形下，不得以任何形式轉借、轉讓本服務予他人或與他人合用。</li>
                <li>雙方當事人之任何一方，發現會員之使用者帳號或個人密碼遭第三人不法或不當之使用者，應立即通知對方。經消費者確認有遭第三人不法或不當之使用情事者，企業經營者應立即暫停該使用者帳號或個人密碼之使用，並接受消費者更換其使用者帳號或個人密碼。</li>
              </ul>
            </li>
            <li>
              <h6> 隱私權聲明</h6>
              <p>會員資料依本公司「隱私權聲明」受到保護及規範，請參閱  隱私權政策</p>
            </li>
            <li>
              <h6>電子報、EDM發送</h6>
              <p>會員同意本公司基於行銷目的，於會員註冊有效期間內，得不定期發送、電子報或EDM至會員所登錄的地址或電子信箱帳號。當會員收到訊息後，如表示拒絕接受行銷時，本公司將停止繼續發送行銷訊息。</p>
            </li>
            <li>
              <h6>
                服務中斷或故障
              </h6>
              <p>本服務有時可能會出現中斷或故障等現象，或是服務設備進行必要之保養及施工時需暫時停止服務；如遇需暫停服務，將於暫停服務時間提前公告，並以電子郵件或網站介面公告通知消費者。</p>
            </li>
            <li>
              <h6>
                會員使用限制規範
              </h6>
              <p class="red">
                您同意不將「本服務」作以下用途：
              </p>
              <ul>
                <li>干擾或破壞「本服務」或與「本服務」相連線之伺服器和網路，或不遵守於本服務連線網路之規定或規範。</li>
                <li>公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上。</li>
                <li>侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
                <li>違反依法律或契約所應負之保密義務。</li>
                <li>冒用他人名義使用本服務。</li>
                <li>傳輸或散佈電腦病毒。</li>
                <li>從事不法或未經本公司授權之商業或廣告宣傳行為。</li>
                <li>其他本公司認為不適當之行為。</li>
              </ul>
            </li>
            <li>
              <h6>智慧財產權保護</h6>
              <p>
                本服務所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依法擁有其智慧財產權，並已為消費者取得本契約利用範圍內之充分授權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，除明確為法律所允許者外，必須依法取得本公司或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任，包括但不限於訴訟費用及律師費用等。
              </p>
            </li>
          </ul>
        </div>
        <div class="clause-box col-12" v-if="clause === 'privacy'">
          <p>
            「LiveABC互動英語教學集團-LiveLearning PRO」網站（下稱LiveABC網站）為「希伯崙股份有限公司」（下稱本公司）所經營；為維護個人隱私權，依中華民國頒立之「個人資料保護法」第8條規定，本公司謹以下列聲明，向LiveABC集團數位平台使用者或會員說明LiveABC集團數位平台，蒐集個人資料之目的、類別、利用範圍及方式、以及您得行使之權利等事項。
          </p>
          <ul>
            <li>LiveABC網站對使用者或會員個人資料的收集，謹遵守中華民國「個人資料保護法」與相關法令之規範。</li>
            <li>當您註冊、使用、瀏覽LiveABC網站，成為網站會員或參與網站宣傳或抽獎等相關活動時，表示您已閱讀、瞭解並同意接受本隱私權聲明之所有內容。本公司有權修改或變更LiveABC網站隱私權聲明之內容，建議您隨時注意本隱私權聲明修改或變更內容資訊。如您於本隱私權內容有修改或變更後，繼續使用LiveABC網站服務，則視為已閱讀、瞭解並同意接受該次修改或變更內容。</li>
            <li>當您使用LiveABC網站使用相關學習服務或參加其他活動時，本公司於完成與您聯繫、完成交易、解決客訴、或處理訂閱程序之相關範圍內，會要求您提供個人資料（包括個人姓名、地址、e-mail、聯絡方式等）。您可以自由選擇是否提供個人資料，但拒絕提供加入會員所需必要之資料，將可能導致您無法使用該項服務，對此本公司不負擔任何賠償責任。</li>
            <li>於課程學習、網路購物、行銷推廣、物流服務、帳務服務、客戶管理及服務或其他合於營業登記項目或章程所訂業務需要之目的範圍內，LiveABC網站將藉由加入會員之過程或進行交易之過程來蒐集、處理、及利用您的個人資料，包括姓名、地址、e-mail、連絡方式、信用卡號等，並對您發送課程活動訊息、商品優惠訊息等相關服務。為了確保會員之個人資料、隱私及消費者權益之保護，依個人資料保護法，本公司將以電郵、傳真、電話、簡訊或其他通知公告方式利用您所提供之資料（資料類別C001、C002、C003、C011等各項類別相關資料）。利用對象除本公司外，亦可能包括相關服務的協力機構。除會員有反對之表示，LiveABC網站得於會員入會期間內，利用會員之個人資料。LiveABC網站對所蒐集之資料依法保密。會員有請求停止蒐集、處理、利用或請求刪除之權利，辦理方式為電話通知客服中心處理（02）25782626#219。</li>
            <li>為了便利使用者，LiveABC網站可能使用cookie設定及技術，以便於提供更適合使用者個人需要的服務；cookie是網站伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦中儲存某些資訊，您可以自由地經由瀏覽器的設定，取消、或限制此項功能。但這可能會導致網站某些功能無法正常執行，對此本公司不負擔任何賠償責任。</li>
            <li>您對於因使用LiveABC網站或註冊為LiveABC網站會員所提供之個人資料，有查詢及閱覽、製給複製本、補充或更正、停止電腦處理及利用、刪除等需求時，可以與客服中心聯絡，LiveABC網站將迅速進行處理。</li>
            <li>
              如果您對LiveABC網站的隱私權聲明有任何疑問，歡迎您以電話或電子郵件與客戶服務中心聯絡。
              客服信箱：livelearning@liveabc.com 【服務時間：24小時】
              客服專線：（02）25782626#219【服務時間：週一至週五9:00~18:00】
            </li>
          </ul>
        </div>
        <div class="col-12" style="text-align: center;">
          <button type="button" @click="goUpdated" class="btn btn-primary">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '../store/api'

export default {
  name: 'First-login',
  data () {
    return {
      current: 'updated',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirm: false,
      checked: false,
      user: {},
      clause: ''
    }
  },
  watch: {
  },
  mounted () {
    this.scrollTop()
    this.getUser()
  },
  computed : {
  },
  watch: {
    confirmPassword (val) {
      if (val === this.newPassword && val !== '') {
        this.confirm = true
      } else {
        this.confirm = false
      }
    },
    newPassword (val) {
      if (val === this.confirmPassword && val !== '') {
        this.confirm = true
      } else {
        this.confirm = false
      }
    }
  },
  methods: {
    async getUser () {
      const { data } = await api.get('/me')
      this.user = data
    },
    goPage (el) {
      this.$router.push({ name: el })
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    goUpdated () {
      this.current = 'updated'
      this.scrollTop()
    },
    goClause (val) {
      this.current = 'clause'
      this.clause = val
      this.scrollTop()
    },
    async updatedPassword () {
      if (this.checked) {
        const self = this
        try {
          await api.post(`update_password`, {
            old_password: this.oldPassword,
            new_password: this.newPassword
          })
          this.$swal({
            title: '儲存成功',
            text: '下次登入請使用新密碼',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: '確定'
          }).then(function (isConfirm) {
          if (isConfirm.value === true) {
            self.current = 'doExam'
          }})
        } catch (error) {
          console.error(error.response.data)
          this.$swal({
            title: '修改密碼失敗',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
        }
      } else {
        this.$swal({
          title: '請先勾選LiveABC之會員條款與隱私權條款',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .title-box {
    h2,h4 {
      font-weight: bolder;
      // color: #fff;
    }
    p {
      width: 80%;
      margin: auto;
      // color: #fff;
    }
  }
  .remind {
    color: red;
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/pic/banner_bg_welcome_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .banner-box {
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: auto;
    }
  }
  .clause {
    padding: 50px 0;
    .clause-box {
      h6{
        font-weight: bolder;
      }
    }
    .clause-box>ul {
      list-style-type: cjk-ideographic;
      li {
        margin: 20px 0;
      }
    }
    .red {
      color: red;
    }
  }
  .bold {
    font-weight: bolder;
  }
</style>
