<template>
  <div>
    <div v-if="lesson" class="banner" v-bind:style="{ 'background-image': 'url(' +  lesson.banner + ')' }">
      <div v-if="breadcrumb" class="breadcrumb">
        <div v-for="(item, key) in breadcrumb" :key="key">
          <span @click="goPage(key)" class="pointer">{{locale==='tw' ? item.name : item.name_en}} </span>
          <span v-show="breadcrumb.length -1 > key"> > </span>
        </div>
      </div>
      <div class="banner-box" >
        <div v-if="lesson" class="title-box">
          <h2>
            {{lesson.name}}
          </h2>
          <h4>
            {{lesson.name_en}}
          </h4>
          <p>
            {{lesson.description}}
          </p>
        </div>
      </div>
    </div>
    <div class="container my-4" style="min-height: 50vh">
      <div class="row">
        <div class="col" style="min-height:80vh">
          <!-- <div class="d-flex my-3" v-if="info">
            <h3 class="title pr-3">{{info.name}}</h3>
            <div class="line"></div>
          </div> -->
          <div v-if="lesson" class="mb-2" style="text-align:left">
            CEFR <span v-for="(item, index) in lesson.cefr.split(',')" :key="index" class="badge badge-dark mx-1">{{item}}</span>
            TOEIC <span class="badge badge-dark">{{lesson.toeic}}</span>
          </div>
          <div v-if="lesson" class="row">
            <div class="col-lg-3 col-md-6 col-sm-12 my-2" v-for="(item, index) in lesson.items"  v-if="item.course_count>0" :key="index" @click="goLesson(item)">
              <button class="btn lesson-btn" type="button">
                <div style="text-align:left">
                  <span v-for="el in item.cefr.split(',')" :key="el" class="badge badge-danger mr-1">{{el}}</span>
                </div>
                <div class="my-2">
                  <div style="border-bottom:1px #ccc solid">
                    <h5>{{locale==='tw' ? item.name : item.name_en}}</h5>
                  </div>
                  <p class="mt-2" style="font-size: 1rem">{{item.course_count}} {{item.course_count > 1? 'Units' : 'Unit'}}</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Pagination v-if="pageCount.length>1" :current="pageIndex" :total="pageCount" v-on:page="toPage"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import api from '../store/api'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'Channels',
  components: {
    Pagination
  },
  data () {
    return {
      locale: window.localStorage.getItem('locale') || 'tw',
      breadcrumb: [],
      lesson: null,
      pageIndex: 1,
      pageSize: 20,
      pageCount: 0
    }
  },
  computed: {
    type () {
      return this.$route.params.channel
    }
  },
  mounted () {
    this.scrollTop ()
    this.getLesson(this.$route.params.channel)
    // this.getParents(this.$route.params.channel)
    // this.getSubType()
  },
  methods: {
    async getLesson (id) {
      const params = {
        page: this.pageIndex,
        pageSize: this.pageSize,
        // channel: id
      }
      const { data } = await api.get(`/contents/package_courses/${id}`, { params })
      this.lesson = data.data
      this.getParents(data.data.category_id)
      this.pageCount = data.pageCount
    },
    async getSubType () {
      const channel = this.$route.params.channel
      const { data } = await api.get(`/contents/channels/${channel}`)
      this.info = data.data
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getLesson(this.$route.params.channel)
    },
    async getParents (id) {
      const { data } = await api.get(`/contents/channels/${id}`)
      const arr = [data.data,this.lesson]
      this.breadcrumb = arr
    },
    goLesson (lesson) {
      if (this.dev) {
        this.$router.push({ name: 'ChannelLesson', params: { lessonId: lesson.lessonId } })
      }else {
        this.$router.push({ name: 'Course', params: { channel: lesson.id } })
      }
    },
    goPage (idx) {
      // console.log(idx)
      if (idx === 0) {
        this.$router.push({ name: 'Home'})
      } else if (idx === 1) {
        this.$router.push({ name: 'Channels', params: { channel: this.breadcrumb[idx].id } })
      }
    },
    scrollTop () {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 95%;
    position: absolute;
    top: 32px;
    border-top: black 1px solid;
  }
  .learning-target {
    width: 100%;
    line-height: 4rem;
    font-size: 1.5rem;
    box-shadow: 4px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  .lesson-btn {
    width: 100%;
    height: 100%;
    margin: 5px 0 0 0;
    border-radius: 5px;
    border: solid rgb(143, 143, 143) 1px;
  }
  .lesson-btn:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/pic/banner_bg_meeting_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .breadcrumb {
      color: #ffff;
      background-color: transparent;
    }
  }
  .title-box {
    h2,h4 {
      font-weight: bolder;
    }
    p {
      width: 70%;
      margin: auto;
      color: #000000;
    }
  }
  @media screen and (max-width: 601px) {
    .title-box {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

</style>
