<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.World_News') }}</h3>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="(item, index) in newsData" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.date).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <img style="width:150px" :src="item.cover" alt="">
                <div class="my-auto mx-2">
                  <p class="p-0 my-1 main-title">{{item.name}}</p>
                  <p class="p-0 my-1">{{item.name_en}}</p>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="goPage(item)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination'
import api from '../store/api'
export default {
  name: 'News',
  components: {
    // DailyNews,
    Pagination
  },
  data () {
    return {
      newsData: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  mounted () {
    this.getNews()
  },
  methods: {
    async getNews() {
      const params = {
        page: this.pageIndex,
        pageSize: 10,
        orderBy: 'desc',
        categories: 2
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.pageCount = data.pageCount
      this.newsData = data.data
    },
    goPage (el) {
      this.$router.push({ name: 'CourseLesson', params: { lesson: el.id, courseId: el.sections[0].data.view_id} })
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getNews()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .pop-title {
    text-align: left;
  }
  .main-title {
    font-size:1.2rem;
    font-weight: bolder;
  }
  .content-box {
    // height: 100%;
    background-color: rgb(230, 230, 230);
    text-align: left;
    padding: 8px;
  }
</style>
